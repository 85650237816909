import ReactLoading from "react-loading";
import axios from "axios";
import { encode as btoa } from "base-64";
import FilterComponent from "components/search";
import moment from "moment";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { toast } from "react-toastify";
import "./Table.css";

interface OwnerPayPalDetails {
  onBoarding: string;
  accountEmail: string;
  merchantId: string;
}

interface Bookings {
  _id: any;
  bookingId: string;
  bookedBy: string;
  day: string;
  ownerResponse: string;
  time: string;
  hours: string;
  status: string;
  date: string;
  type: string;
  propertyId?: string;
  startTime?: string;
  endTime?: string;
  paymentStatus: string;
  ownerPayPalDetails?: OwnerPayPalDetails;
}

const BookingManagementList: FC<any> = () => {
  const [filterText, setFilterText] = useState("");
  const [data, setData] = useState<Bookings[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showRefundConfirmation, setShowRefundConfirmation] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<Bookings | null>(null);

  const [loading, setLoading] = useState(false);

  const getStatusBadgeClasses = (ownerResponse: string) => {
    let bgColor = "";
    let textColor = "";
    let extraClasses = "px-2.5 py-0.5 rounded";

    switch (ownerResponse) {
      case "ACCEPTED":
        bgColor = "bg-green-500";
        textColor = "text-white";
        break;
      case "PENDING":
        bgColor = "bg-yellow-400";
        textColor = "text-black";
        break;
      case "DECLINED":
        bgColor = "bg-red-400";
        textColor = "text-white";
        break;
      default:
        bgColor = "bg-gray-400";
        textColor = "text-white";
        break;
    }

    return `${extraClasses} ${bgColor} ${textColor} dark:bg-gray-700 dark:text-white w-25`;
  };

  const generateToken = async () => {
    const baseUrl = "https://api-m.sandbox.paypal.com";
    const clientId = process.env.REACT_APP_CLIENTID;
    const secretKey = process.env.REACT_APP_SECRETKEY;

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${btoa(`${clientId}:${secretKey}`)}`,
    };

    const data = "grant_type=client_credentials";

    try {
      const response = await axios.post(`${baseUrl}/v1/oauth2/token`, data, {
        headers: headers,
      });
      const { access_token } = response.data;
      return access_token;
    } catch (error) {}
  };
  const getAuthAssertionValue = async (clientId: any, sellerPayerId: any) => {
    const header = {
      alg: "none",
    };

    const encodedHeader = await base64url(header);

    const payload = {
      iss: clientId,
      payer_id: sellerPayerId,
    };
    const encodedPayload = await base64url(payload);
    return `${encodedHeader}.${encodedPayload}.`;
  };
  function base64url(json: any) {
    return btoa(JSON.stringify(json))
      .replace(/=+$/, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");
  }

  const refundPayment = async (
    token: string,
    accountEmail: string,
    captureId: string
  ) => {
    const paypal_auth_assertion: string = await getAuthAssertionValue(
      process.env.REACT_APP_CLIENTID,
      accountEmail
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "PayPal-Auth-Assertion": paypal_auth_assertion,
    };

    const data = {};

    try {
      const response = await axios.post(
        `https://api.sandbox.paypal.com/v2/payments/captures/${captureId}/refund`,
        data,
        { headers }
      );

      return response?.data;
    } catch (error: any) {
      if (
        error?.response &&
        error?.response?.data &&
        error?.response?.data?.details
      ) {
        const description =
          error.response.data.details[0].description ||
          "Refund Already Issued for this Payment";
        toast.error(description);
      } else {
        toast.error("An Unknown Error has occured");
      }
    }
  };

  const getUpLinkResponse = async (upLink: string, token: string) => {
    if (!upLink) {
      console.error("Refund already issued.");
      return null;
    }
    try {
      const response = await axios.get(upLink, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      console.log("Up Link Response:", response);
      toast.success("Payment Refunded Succesfully");
      return response;
    } catch (error) {
      console.error("Error fetching upLink:", error);
    }
  };

  const showRefundConfirmationModal = (booking: Bookings) => {
    setSelectedBooking(booking);
    setShowRefundConfirmation(true);
  };

  const hideRefundConfirmationModal = () => {
    setSelectedBooking(null);
    setShowRefundConfirmation(false);
  };

  const handleRefund = (row: Bookings) => {
    showRefundConfirmationModal(row);
  };

  const handleConfirmRefund = async () => {
    try {
      hideRefundConfirmationModal();

      setIsLoading(true);

      const bookingId = selectedBooking?.bookingId;
      let apiUrl = `${process.env.REACT_APP_API_AWS_URL}/payment/getPayments/${bookingId}`;

      const response = await axios.get(apiUrl);

      if (response?.data?.Status === 400) {
        toast.error(response?.data?.Message);

        setIsLoading(false);

        return;
      } else {
        const token = await generateToken();
        const captureId =
          response?.data?.Data?.purchase_units[0]?.payments?.captures[0]?.id;
        const accountEmail = selectedBooking?.ownerPayPalDetails?.accountEmail;

        const refundResponse = await refundPayment(
          token,
          accountEmail,
          captureId
        );

        const upLink = refundResponse?.links?.find(
          (link: any) => link?.rel === "up"
        )?.href;
        const getUpLinksResponse = await getUpLinkResponse(upLink, token);
        const refundApiResponse = await axios.post(
          `${process.env.REACT_APP_API_AWS_URL}/refund/refundPayment`,
          getUpLinksResponse?.data
        );

        fetchData();

        setIsLoading(false);
      }
    } catch (error: any) {
      hideRefundConfirmationModal();

      setIsLoading(false);
    }
  };

  const getPaymentStatusBadgeClasses = (paymentStatus: string) => {
    let bgColor = "";
    let textColor = "";
    let extraClasses = "px-1.5 py-0.5 rounded";

    switch (paymentStatus) {
      case "REFUNDED":
        bgColor = "bg-yellow-400";
        textColor = "text-black";
        break;
      case "COMPLETED":
        bgColor = "bg-green-500";
        textColor = "text-white";
        break;
      case "FAIL":
        bgColor = "bg-red-400";
        textColor = "text-white";
        break;
      default:
        bgColor = "bg-gray-400";
        textColor = "text-white";
        break;
    }

    return `${extraClasses} ${bgColor} ${textColor} dark:bg-gray-700 dark:text-white w-25`;
  };
  const paginationOptions = {
    rowsPerPageText: "Items Per Page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_AWS_URL}/properties/bookingManagementForAdmin`
      );
      setData(response?.data?.Data);
    } catch (error: any) {
      if (error.response?.data?.status === 404) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("Page not found!");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns: TableColumn<Bookings>[] = [
    {
      name: "Booking By",
      selector: (row: { bookedBy: string }) => row.bookedBy,
      sortable: true,
      id: "bookedBy",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
    {
      name: "Facility Type",
      selector: (row: { type: string }) => row.type,
      sortable: true,
      id: "type",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
    {
      name: "Booking Date",
      selector: (row: { date: string }) => row.date,
      sortable: true,
      id: "date",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
      },
      format: (row: { date: string }) => {
        const formattedDate = moment(
          row.date,
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        ).format("YYYY-MM-DD");
        return moment(formattedDate, "DD-MM-YYYY").isValid()
          ? formattedDate
          : "Invalid Date";
      },
    },
    {
      name: "Booked Time",
      selector: (row: { time: string }) => row.time,
      sortable: true,
      id: "time",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
      },
    },
    {
      name: "Duration",
      selector: (row: { hours: string }) => row.hours,
      sortable: true,
      id: "hours",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
      },
    },
    {
      name: "Status",
      selector: (row: { ownerResponse: string }) => row.ownerResponse,
      sortable: true,
      id: "ownerResponse",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
      },
      cell: (row: { ownerResponse: string }) => (
        <span className={getStatusBadgeClasses(row.ownerResponse)}>
          {row.ownerResponse}
        </span>
      ),
    },
    {
      name: "Payment Status",
      selector: (row: { paymentStatus: string }) => row.paymentStatus,
      sortable: true,
      id: "paymentStatus",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
      },
      cell: (row: { paymentStatus: string }) => (
        <span className={getPaymentStatusBadgeClasses(row.paymentStatus)}>
          {row.paymentStatus}
        </span>
      ),
    },
    {
      name: "Refund",
      cell: (row: Bookings) => {
        if (
          row.ownerResponse === "PENDING" &&
          row.paymentStatus === "COMPLETED"
        ) {
          return (
            <button
              onClick={() => handleRefund(row)}
              className="rounded bg-blue-500 px-4 py-1 text-white hover:bg-blue-700"
            >
              Refund
            </button>
          );
        } else {
          return (
            <button
              disabled
              className="cursor-not-allowed rounded bg-gray-400 px-4 py-1 text-white"
            >
              Refund
            </button>
          );
        }
      },
    },
  ];

  const filteredItems = useMemo(() => {
    return data?.filter((item) => {
      const bookingIdMatch =
        item?.bookedBy &&
        item?.bookedBy?.toLowerCase().includes(filterText?.toLowerCase());
      const facilityTypeMatch =
        item?.type &&
        item?.type?.toLowerCase().includes(filterText?.toLowerCase());
      const bookingDateAndTimeMatch =
        item?.date &&
        item?.date?.toLowerCase().includes(filterText?.toLowerCase());
      const durationMatch =
        item?.hours &&
        item?.hours?.toLowerCase().includes(filterText?.toLowerCase());
      const statusMatch =
        item?.ownerResponse &&
        item?.ownerResponse?.toLowerCase().includes(filterText?.toLowerCase());
      const paymentStatusMatch =
        item?.paymentStatus &&
        item?.paymentStatus?.toLowerCase().includes(filterText?.toLowerCase());
      return (
        bookingIdMatch ||
        facilityTypeMatch ||
        bookingDateAndTimeMatch ||
        durationMatch ||
        statusMatch ||
        paymentStatusMatch
      );
    });
  }, [data, filterText]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e: ChangeEvent<HTMLInputElement>) =>
          setFilterText(e.target.value)
        }
        filterText={filterText}
      />
    );
  }, [filterText]);

  return (
    <>
      {isLoading ? (
        <div className="bg-black fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-opacity-30">
          <div className="rounded-full bg-white p-5">
            <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-blue-500"></div>
          </div>
        </div>
      ) : (
        <div className="user-management-header">
          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            paginationComponentOptions={paginationOptions}
            persistTableHead
            progressPending={loading}
            progressComponent={
              <ReactLoading
                type={"cylon"}
                color={"#14aadd"}
                height={80}
                width={80}
              />
            }
          />
        </div>
      )}
      {showRefundConfirmation && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure you want to refund this booking?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleConfirmRefund}
                className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
              >
                Yes
              </button>
              <button
                onClick={hideRefundConfirmationModal}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookingManagementList;
