import axios from "axios";
import ReactLoading from "react-loading";
import FilterComponent from "components/search";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { TableColumn } from "react-data-table-component";
import DataTable from "react-data-table-component";
import { HiOutlineViewGrid } from "react-icons/hi";
import { toast } from "react-toastify";
import "./Table.css";
import ViewMoreModal from "./ViewMoreModal";

interface User {
  _id: any;
  name: string;
  email: string;
  messages: {
    subject: string;
    message: string;
    createdAt: string;
  }[];
}

const paginationOptions = {
  rowsPerPageText: "Items Per Page:",
  rangeSeparatorText: "of",
  selectAllRowsItem: true,
  selectAllRowsItemText: "All",
};

const ContactUsManagementList: FC<any> = () => {
  const [filterText, setFilterText] = useState("");
  const [data, setData] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isViewMoreModalOpen, setIsViewMoreModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_AWS_URL}/user/userListWithMessages`
      );
      setData(response?.data?.Data);
    } catch (error: any) {
      if (error.response?.data?.status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleViewMore = (user: User) => {
    setSelectedUser(user);
    setIsViewMoreModalOpen(true);
  };

  const caseInsensitiveSort = (rowA: User, rowB: User) => {
    const nameA = (rowA.name || "-").toLowerCase();
    const nameB = (rowB.name || "-").toLowerCase();
    const emailA = rowA.email.toLowerCase();
    const emailB = rowB.email.toLowerCase();

    if (nameA === nameB) {
      return emailA.localeCompare(emailB, undefined, { sensitivity: "base" });
    }
    return nameA.localeCompare(nameB, undefined, { sensitivity: "base" });
  };

  const columns: TableColumn<User>[] = [
    {
      name: "Name",
      selector: (row: User) => row.name || "-",
      sortable: true,
      id: "Name",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
      sortFunction: caseInsensitiveSort,
    },
    {
      name: "Email",
      selector: (row: User) => row.email,
      id: "Email",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "lowercase",
      },
    },
    {
      name: "Actions",
      id: "Actions",
      cell: (row: User) => (
        <div>
          <button
            onClick={() => handleViewMore(row)}
            className="mr-2 flex w-[95px] rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          >
            <HiOutlineViewGrid className="h-5 w-5" />
            <span className="ml-2 text-sm">View</span>
          </button>
        </div>
      ),
    },
  ];

  const filteredItems = useMemo(() => {
    return data?.filter((item) => {
      const nameMatch =
        item?.name &&
        item?.name?.toLowerCase()?.includes(filterText.toLowerCase());
      const emailMatch =
        item?.email &&
        item?.email?.toLowerCase()?.includes(filterText.toLowerCase());

      return nameMatch || emailMatch;
    });
  }, [data, filterText]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e: ChangeEvent<HTMLInputElement>) =>
          setFilterText(e.target.value)
        }
        filterText={filterText}
      />
    );
  }, [filterText]);

  return (
    <>
      <div className="contact-us-management-table-header">
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={
            <ReactLoading
              type={"cylon"}
              color={"#14aadd"}
              height={80}
              width={80}
            />
          }
        />
      </div>
      <ViewMoreModal
        isOpen={isViewMoreModalOpen}
        onClose={() => setIsViewMoreModalOpen(false)}
        user={selectedUser}
      />
    </>
  );
};

export default ContactUsManagementList;
