import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";

type RevenueSharingTypes = {
  owner: number;
  admin: number;
};

const validationSchema = Yup.object().shape({
  owner: Yup.number()
    .required("Owner's Percentage is Required")
    .positive("Percentage Must Be Positive")
    .min(0, "Please Enter Valid Percentage")
    .max(100, "Percentage is Not Valid")
    .test("maxOwner", "Please Enter Valid Percentage Ratio", function (value) {
      const adminPercentage = this.parent.admin || 0;
      const sum = value + adminPercentage;
      return sum === 100;
    }),
  admin: Yup.number()
    .required("Admin's Percentage is Required")
    .positive("Percentage Must Be Positive")
    .min(0, "Please Enter Valid Percentage")
    .max(100, "Percentage is Not Valid")
    .test("maxAdmin", "Please Enter Valid Percentage Ratio", function (value) {
      const ownerPercentage = this.parent.owner || 0;
      const sum = value + ownerPercentage;
      return sum === 100;
    }),
});

const RevenueSharing = () => {
  const [data, setData] = useState(undefined);
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);

  const hideEditConfirmationModal = () => {
    setShowEditConfirmation(false);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_AWS_URL}/paymentRatio/getPaymentRatio`
      );
      setData(response?.data?.Data);
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.Message || "An error occurred";

      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (values: RevenueSharingTypes) => {
    const { admin, owner } = values;
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_AWS_URL}/paymentRatio/updatePaymentRatio`,
        {
          paymentRatio: {
            owner: owner,
            admin: admin,
          },
        }
      );
      fetchData();
      setShowEditConfirmation(false);
      toast(response?.data?.Message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error: any) {
      if (error.response?.data?.status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  const initialValues = {
    owner: data?.owner || 0,
    admin: data?.admin || 0,
  };
  return (
    <div className="animate-fadeIn mt-10 flex flex-col items-center justify-center space-y-8 md:flex-row md:space-x-8 md:space-y-0">
      <div className="animate-slideInUp w-full md:w-2/5">
        <div className="space-y-4 rounded-lg bg-white p-8 shadow-md">
          <h2 className="text-black text-center text-2xl font-semibold">
            Revenue Sharing
          </h2>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ errors, values, setFieldTouched }) => (
              <Form>
                <div className="mb-4">
                  <label htmlFor="owner" className="mb-2 block text-gray-700">
                    Owner Percentage
                  </label>
                  <Field
                    type="number"
                    name="owner"
                    className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                  />
                  <ErrorMessage
                    name="owner"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="admin" className="mb-2 block text-gray-700">
                    Admin Percentage
                  </label>
                  <Field
                    type="number"
                    name="admin"
                    className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                  />
                  <ErrorMessage
                    name="admin"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <button
                    type="button"
                    onClick={() => {
                      if (
                        Object.keys(errors).length === 0 &&
                        values.admin + values.owner === 100
                      ) {
                        setShowEditConfirmation(true);
                      } else {
                        setFieldTouched("owner");
                        setFieldTouched("admin");
                      }
                    }}
                    className="focus:shadow-outline mt-4 w-full rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
                  >
                    Save Percentage
                  </button>
                </div>
                {showEditConfirmation && (
                  <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
                    <div className="rounded bg-white p-6">
                      <p>Are you sure you want to Update the Percentage?</p>
                      <div className="mt-4 flex items-center justify-center">
                        <button
                          type="submit"
                          onClick={() => handleSubmit}
                          className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
                        >
                          Yes
                        </button>
                        <button
                          onClick={hideEditConfirmationModal}
                          className="rounded bg-gray-500 px-4 py-2 text-white"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RevenueSharing;
