import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import * as Yup from "yup";
import axios from "axios";

interface OwnerType {
  _id: any;
  name: string;
  email: string;
  DOB: string;
}

interface EditModalProps {
  owner: OwnerType | null;
  onClose: () => void;
  setData: any;
  fetchData: () => void;
}

const EditModal: React.FC<EditModalProps> = ({
  owner,
  onClose,
  setData,
  fetchData,
}) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .trim()
      .matches(/^[a-zA-Z\s]*$/, "Only alphabets are allowed for the name")
      .test(
        "no-multiple-spaces",
        "Name should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      )
      .max(30, "Owner Name must not exceed 30 characters"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .test("valid-domain", "Invalid domain name in email", (value) =>
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
      ),
    DOB: Yup.string()
      .required("Date of Birth is required")
      .test(
        "valid-date",
        "Invalid Date of Birth",
        (value) => value && moment(value, "DD-MM-YYYY").isValid()
      ),
  });

  const handleSubmit = async (values: any) => {
    const { name, email, DOB } = values;
    onClose();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_AWS_URL}/updateOwner/${values._id}`,
        {
          name: name,
          email: email,
          DOB: DOB,
        }
      );
      const userData = response.data;
      setData((prevData: any) => {
        const updatedData = prevData.map((item: any) =>
          item._id === userData._id ? userData : item
        );
        return updatedData;
      });
      fetchData();
      toast(response?.data?.Message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error: any) {
      if (error.response?.data?.Status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
      <div className="mx-auto w-full max-w-md rounded-lg bg-white p-6 shadow-md">
        <h2 className="mb-4 text-center text-2xl font-semibold">
          Edit Owner Details
        </h2>
        <Formik
          initialValues={owner}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>
              <div className="mb-4">
                <label className="block text-gray-700" htmlFor="name">
                  Name:
                </label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  className={`w-full rounded-md border ${
                    errors.name && touched.name
                      ? "border-red-500"
                      : "border-gray-300"
                  } px-3 py-2 focus:border-blue-500 focus:outline-none`}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="mt-1 text-red-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700" htmlFor="email">
                  Email:
                </label>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  className={`w-full rounded-md border ${
                    errors.email && touched.email
                      ? "border-red-500"
                      : "border-gray-300"
                  } px-3 py-2 focus:border-blue-500 focus:outline-none`}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="mt-1 text-red-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700" htmlFor="DOB">
                  DOB:
                </label>
                <div className="w-full rounded-md border border-gray-300">
                  <DatePicker
                    id="DOB"
                    name="DOB"
                    selected={moment(values.DOB, "DD-MM-YYYY").toDate()}
                    onChange={(date) => {
                      setFieldValue("DOB", moment(date).format("DD-MM-YYYY"));
                    }}
                    className={`z-50 w-full px-3 py-2 focus:border-blue-500 focus:outline-none ${
                      errors.DOB && touched.DOB
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={10}
                  />
                </div>
                <ErrorMessage
                  name="DOB"
                  component="div"
                  className="mt-1 text-red-500"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="rounded-md bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-600 focus:outline-none"
                >
                  Save
                </button>
                <button
                  type="button"
                  className="ml-2 rounded-md border border-gray-300 px-4 py-2 focus:outline-none"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditModal;
