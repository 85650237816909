// Import statements
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import * as Yup from "yup";

interface email {
  email: string;
}

const ForgotPassword = () => {
  // Initial values for the form
  const initialValues = {
    email: "",
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .test("valid-domain", "Invalid domain name in email", (value) =>
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
      ),
  });

  // Handle form submission
  const handleSubmit = async (
    values: email, // Provide the type for values
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setSubmitting(false);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_AWS_URL}/forgot-password`,
        {
          email: values.email,
        }
      );
      toast(response?.data?.Message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error: any) {
      if (error?.response?.data?.Status === 404) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (error?.response?.data?.Status === 500) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-center text-4xl font-bold text-navy-700 dark:text-white">
          Forgot Password?
        </h4>
        <p className="mb-9 ml-1 text-center text-base text-gray-600">
          Don’t worry! It happens. Please enter the email address associated
          with your account
        </p>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            {/* Email */}
            <div className="mb-3">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <Field
                type="email"
                id="email"
                name="email"
                placeholder="Enter your Email"
                className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none focus:border-gray-700 dark:!border-white/10 dark:text-white"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-sm text-red-500"
              />
            </div>

            {/* Checkbox */}
            <div className="mb-4 flex items-center justify-between px-2">
              <Link
                to="/auth/sign-In"
                className="text-sm font-medium text-[#14AADD] hover:text-brand-600 dark:text-white"
              >
                Back to sign in ➔
              </Link>
            </div>

            <button
              type="submit"
              className="linear mt-2 w-full rounded-xl bg-[#14AADD]  py-[12px] text-base font-medium text-white transition duration-200 hover:bg-[#14AADD]  active:bg-[#14AADD]  dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Submit
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPassword;
