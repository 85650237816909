import axios from "axios";
import FilterComponent from "components/search";
import moment from "moment";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { TableColumn } from "react-data-table-component";
import DataTable from "react-data-table-component";
import { BsPersonFillAdd } from "react-icons/bs";
import { MdEditSquare } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { toast } from "react-toastify";
import dummyImage from "../../../assets/img/avatars/UserAvtar.png";
import "./Table.css";
import AddUser from "./components/AddUser";
import EditModal from "./components/EditModal";
import ReactLoading from "react-loading";

interface User {
  _id: any;
  name: string;
  email: string;
  DOB: string;
  date: string;
  profileImage: string;
}

const paginationOptions = {
  rowsPerPageText: "Items Per Page:",
  rangeSeparatorText: "of",
  selectAllRowsItem: true,
  selectAllRowsItemText: "All",
};

const dateSort = (a: User, b: User) => {
  const dateA = moment(a.DOB, "DD/MM/YYYY").toDate();
  const dateB = moment(b.DOB, "DD/MM/YYYY").toDate();
  return dateA.getTime() - dateB.getTime();
};

const caseInsensitiveSort = (rowA: User, rowB: User) => {
  const nameA = rowA.name.toLowerCase();
  const nameB = rowB.name.toLowerCase();
  const emailA = rowA.email.toLowerCase();
  const emailB = rowB.email.toLowerCase();

  if (nameA === nameB && emailA === emailB) {
    return 0;
  }

  return (
    nameA.localeCompare(nameB, undefined, { sensitivity: "base" }) ||
    emailA.localeCompare(emailB, undefined, { sensitivity: "base" })
  );
};

const UserManagementList: FC<any> = () => {
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [editDetails, setEditDetails] = useState(false);
  const [addUserDetails, setAddUserDetails] = useState(false);
  const [data, setData] = useState<User[]>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState<number | null>(null);

  const showDeleteConfirmationModal = (id: number) => {
    setDeleteUserId(id);
    setShowDeleteConfirmation(true);
  };

  const hideDeleteConfirmationModal = () => {
    setShowDeleteConfirmation(false);
    setDeleteUserId(null);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_AWS_URL}/admin/delete-user-profile/${deleteUserId}`
      );
      toast(response?.data?.Message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      fetchData();
      hideDeleteConfirmationModal();
    } catch (error: any) {
      if (error.response?.data?.status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_AWS_URL}/admin/get-user-info`
      );
      setData(response?.data?.userData);
    } catch (error: any) {
      if (error.response?.data?.status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (id: number) => {
    const user = data.find((user) => user._id === id);
    setSelectedUser(user);
    setEditDetails(true);
  };

  const columns: TableColumn<User>[] = [
    {
      name: "Image",
      cell: (row: { profileImage: string }) => (
        <img
          className="h-10 w-10"
          src={row.profileImage || dummyImage}
          alt=""
        />
      ),
      sortable: false,
      id: "Image",
    },
    {
      name: "Name",
      selector: (row: { name: string }) => row.name,
      sortable: true,
      id: "Name",
      sortFunction: caseInsensitiveSort,
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
    {
      name: "Email",
      selector: (row: { email: string }) => row.email,
      sortable: false,
      id: "Email",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "lowercase",
      },
    },
    {
      name: "DOB",
      selector: (row: { DOB: string }) => row.DOB,
      sortable: true,
      id: "DOB",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
      },
      format: (row: { DOB: string }) => {
        const formattedDate = moment(row.DOB, "DD-MM-YYYY").format(
          "DD/MM/YYYY"
        );
        return moment(formattedDate, "DD/MM/YYYY").isValid()
          ? formattedDate
          : "Invalid Date";
      },
      sortFunction: dateSort,
    },
    {
      name: "Actions",
      id: "Actions",
      cell: (row: { _id: number }) => (
        <div className="flex">
          <button
            onClick={() => handleEdit(row._id)}
            className="mr-2 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          >
            <MdEditSquare className="h-4 w-4" />
          </button>
          <button
            onClick={() => showDeleteConfirmationModal(row._id)}
            className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
          >
            <RiDeleteBin5Line className="h-4 w-4" />
          </button>
        </div>
      ),
    },
  ];

  const filteredItems = useMemo(() => {
    return data?.filter((item) => {
      const nameMatch =
        item.name && item.name.toLowerCase().includes(filterText.toLowerCase());
      const emailMatch =
        item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase());

      return nameMatch || emailMatch;
    });
  }, [data, filterText]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleAddUserDetails = () => {
      setAddUserDetails(true);
    };

    return (
      <FilterComponent
        onFilter={(e: ChangeEvent<HTMLInputElement>) =>
          setFilterText(e.target.value)
        }
        handleChangeDetails={handleAddUserDetails}
        filterText={filterText}
        addText={"Add User"}
        icon={<BsPersonFillAdd className="h-6 w-6" />}
      />
    );
  }, [filterText]);

  return (
    <>
      {editDetails && (
        <EditModal
          user={selectedUser}
          onClose={() => setEditDetails(false)}
          setData={setData}
          fetchData={fetchData}
        />
      )}
      {addUserDetails && (
        <AddUser
          onClose={() => setAddUserDetails(false)}
          setData={setData}
          fetchData={fetchData}
        />
      )}
      {showDeleteConfirmation && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure you want to delete the user?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleDeleteConfirmation}
                className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
              >
                Yes
              </button>
              <button
                onClick={hideDeleteConfirmationModal}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="user-management-header">
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={
            <ReactLoading
              type={"cylon"}
              color={"#14aadd"}
              height={80}
              width={80}
            />
          }
        />
      </div>
    </>
  );
};

export default UserManagementList;
