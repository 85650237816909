import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import * as Yup from "yup";

const getFormattedTime = (time: any) => {
  if (!time) return null;
  const Time = new Date(time);
  const hours = Time.getHours().toString().padStart(2, "0");
  const minutes = Time.getMinutes().toString().padStart(2, "0");
  const formattedTime = `${hours}:${minutes}`;
  return formattedTime;
};
const AddPropertyPage = () => {
  const { propertyId } = useParams();

  const validationSchema = Yup.object().shape({
    images: Yup.array()
      .required("Image is required")
      .min(1, "At least one image is required")
      .max(5, "You can only upload a maximum of 5 images"),
    name: Yup.string()
      .required("Property Name is required")
      .trim()
      .test(
        "no-multiple-spaces",
        "Property Name should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      )
      .max(40, "Property Name must not exceed 40 characters")
      .matches(
        /^[A-Za-z0-9\s]*$/,
        "Only alphabets and numbers are allowed for the name"
      ),
    type: Yup.string()
      .required("Property Type is required")
      .oneOf(["gym", "basketball"], "Invalid property type"),
    size: Yup.number()
      .typeError("Size/Area must be a number")
      .required("Size/Area is required")
      .min(0, "Size/Area must be a positive value"),
    price: Yup.number()
      .typeError("Price must be a number")
      .required("Price is required")
      .min(0, "Price must be a positive value"),
    description: Yup.string()
      .required("Description is required")
      .trim()
      .test(
        "no-multiple-spaces",
        "Description should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      ),
    location: Yup.string()
      .required("Location is required")
      .trim()
      .matches(/^[A-Za-z0-9\s]*$/, "No Special Characters are Allowed")
      .test(
        "no-multiple-spaces",
        "Location should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      ),
    weeklyTimeSlots: Yup.array().of(
      Yup.object().shape({
        closeTime: Yup.date().test(
          "not-equal",
          "Open Time and Close Time should not be the same",
          function (closeTime) {
            const { openTime } = this.parent;
            const formattedOpenTime = getFormattedTime(openTime);
            const formattedCloseTime = getFormattedTime(closeTime);
            return openTime ? formattedOpenTime !== formattedCloseTime : true;
          }
        ),
        openTime: Yup.date().test(
          "not-equal",
          "Please Enter Valid Open Time",
          function (openTime) {
            const { closeTime } = this.parent;
            const formattedOpenTime = getFormattedTime(openTime);
            const formattedCloseTime = getFormattedTime(closeTime);

            if (formattedOpenTime && formattedCloseTime) {
              if (formattedOpenTime > formattedCloseTime) {
                return false;
              }
            }

            return true;
          }
        ),
      })
    ),
    closeTime: Yup.date().test(
      "not-equal",
      "Open Time and Close Time should not be the same",
      function (closeTime) {
        const { openTime } = this.parent;
        const formattedOpenTime = getFormattedTime(openTime);
        const formattedCloseTime = getFormattedTime(closeTime);

        if (formattedOpenTime && formattedCloseTime) {
          if (formattedOpenTime >= formattedCloseTime) {
            return false; // Return false to indicate validation failure
          }
        }

        return true;
      }
    ),
  });

  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    try {
      const formData = new FormData();
      formData.append("createdBy", propertyId.toString());
      formData.append("name", values.name);
      formData.append("type", values.type);
      formData.append("size", values.size);
      formData.append("price", values.price);
      formData.append("description", values.description);
      formData.append("location", values.location);

      const formattedTimeSlots = values.weeklyTimeSlots.map((i: any) => {
        const obj = { ...i };
        const formattedOpenTime = getFormattedTime(i.openTime);
        const formattedCloseTime = getFormattedTime(i.closeTime);

        if (!obj.status || (!formattedOpenTime && !formattedCloseTime)) {
          obj.openTime = "06:00";
          obj.closeTime = "18:00";
        } else {
          obj.openTime = formattedOpenTime;
          obj.closeTime = formattedCloseTime;
        }
        return obj;
      });

      formData.append("weeklyTimeSlots", JSON.stringify(formattedTimeSlots));

      for (let i = 0; i < amenities.length; i++) {
        formData.append("amenities", amenities[i]);
      }

      for (let i = 0; i < values.images.length; i++) {
        formData.append("images", values.images[i]);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_AWS_URL}/properties/createProperty`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast(response?.data?.Message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error: any) {
      toast(error?.response?.data?.Message || "An error occurred", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    navigate("/admin/owner-list");
  };
  const initialVal: any = {
    name: "",
    type: null,
    size: "",
    price: "",
    description: "",
    location: "",
    images: [],
    weeklyTimeSlots: [
      {
        day: "Monday",
        openTime: "",
        closeTime: "",
        status: false,
        customTimeSelected: false,
      },
      {
        day: "Tuesday",
        openTime: "",
        closeTime: "",
        status: false,
        customTimeSelected: false,
      },
      {
        day: "Wednesday",
        openTime: "",
        closeTime: "",
        status: false,
        customTimeSelected: false,
      },
      {
        day: "Thursday",
        openTime: "",
        closeTime: "",
        status: false,
        customTimeSelected: false,
      },
      {
        day: "Friday",
        openTime: "",
        closeTime: "",
        status: false,
        customTimeSelected: false,
      },
      {
        day: "Saturday",
        openTime: "",
        closeTime: "",
        status: false,
        customTimeSelected: false,
      },
      {
        day: "Sunday",
        openTime: "",
        closeTime: "",
        status: false,
        customTimeSelected: false,
      },
    ],
    amenities: [],
  };
  const [amenities, setAmenities] = useState([]);
  const [currentTag, setCurrentTag] = useState("");
  // const [customTimeSelected, setCustomTimeSelected] = useState(false);

  const handleTagAdd = (event: any) => {
    event.preventDefault();

    const trimmedTag = currentTag.trim();

    if (trimmedTag !== "") {
      setAmenities([...amenities, trimmedTag]);
      setCurrentTag("");
    }
  };
  const handleCancleAddPropertyButton = () => {
    navigate("/admin/owner-list");
  };

  const handleTagDelete = (index: any) => {
    const newTags = [...amenities];
    newTags.splice(index, 1);
    setAmenities(newTags);
  };

  return (
    <>
      <div className="mx-auto w-full rounded-lg bg-white p-6 shadow-md">
        <h2 className="mb-4 text-center text-2xl font-semibold">
          Add Property Details
        </h2>
        <Formik
          initialValues={initialVal}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values, handleChange, setFieldValue }) => (
            <Form>
              <div className="flex space-x-4">
                <div className="mb-4 flex-1">
                  <label
                    className="block text-base font-bold text-gray-700"
                    htmlFor="name"
                  >
                    Property Name <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    className={`w-full rounded-md border ${
                      errors.name && touched.name
                        ? "border-red-500"
                        : "border-gray-300"
                    } bg-gray-100 px-3 py-2 text-gray-800 focus:border-blue-500 focus:outline-none`}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
                <div className="mb-4 flex-1">
                  <label
                    className="block text-base font-bold text-gray-700"
                    htmlFor="type"
                  >
                    Property Type <span className="text-red-500">*</span>
                  </label>
                  <Field
                    as="select"
                    id="type"
                    name="type"
                    onChange={handleChange}
                    className={`w-full rounded-md border ${
                      errors.type && touched.type
                        ? "border-red-500"
                        : "border-gray-300"
                    } bg-gray-100 px-3 py-2 text-gray-800 focus:border-blue-500 focus:outline-none`}
                  >
                    <option value="" label="Select a type" />
                    <option value="gym" label="Gym" />
                    <option value="basketball" label="Basketball" />
                  </Field>
                  <ErrorMessage
                    name="type"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
              </div>
              <div className="flex space-x-4">
                <div className="mb-4 flex-1">
                  <label
                    className="block text-base font-bold text-gray-700"
                    htmlFor="size"
                  >
                    Property Size <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="text"
                    id="size"
                    name="size"
                    maxLength={4}
                    className={`w-full rounded-md border ${
                      errors.size && touched.size
                        ? "border-red-500"
                        : "border-gray-300"
                    } bg-gray-100 px-3 py-2 text-gray-800 focus:border-blue-500 focus:outline-none`}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                  />
                  <ErrorMessage
                    name="size"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
                <div className="mb-4 flex-1">
                  <label
                    className="block text-base font-bold text-gray-700"
                    htmlFor="price"
                  >
                    Price (Hourly) <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="text"
                    id="price"
                    name="price"
                    maxLength={5}
                    className={`w-full rounded-md border ${
                      errors.price && touched.price
                        ? "border-red-500"
                        : "border-gray-300"
                    } bg-gray-100 px-3 py-2 text-gray-800 focus:border-blue-500 focus:outline-none`}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                  />
                  <ErrorMessage
                    name="price"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
              </div>
              <div className="flex space-x-4">
                <div className="mb-4 flex-1">
                  <label
                    className="block text-base font-bold text-gray-700"
                    htmlFor="description"
                  >
                    Description <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="text"
                    id="description"
                    name="description"
                    className={`w-full rounded-md border ${
                      errors.description && touched.description
                        ? "border-red-500"
                        : "border-gray-300"
                    } bg-gray-100 px-3 py-2 text-gray-800 focus:border-blue-500 focus:outline-none`}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
              </div>
              <div className="flex space-x-4">
                <div className="mb-4 flex-1">
                  <label
                    className="block text-base font-bold text-gray-700"
                    htmlFor="location"
                  >
                    Location <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="text"
                    id="location"
                    name="location"
                    className={`w-full rounded-md border ${
                      errors.location && touched.location
                        ? "border-red-500"
                        : "border-gray-300"
                    } bg-gray-100 px-3 py-2 text-gray-800 focus:border-blue-500 focus:outline-none`}
                  />
                  <ErrorMessage
                    name="location"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
              </div>
              <div className="mb-2 w-full">
                <label htmlFor="#" className="block text-lg font-bold">
                  Upload Images <span className="text-red-500">*</span>
                </label>
                <Field name="images">
                  {({ form }: any) => (
                    <input
                      type="file"
                      id="images"
                      name="images"
                      multiple
                      accept="image/jpeg, image/png"
                      onChange={(event: any) => {
                        const files = event.currentTarget.files;
                        if (files) {
                          const imageFiles = Array.from(files).filter(
                            (file) => {
                              const isImage =
                                (file as File).type === "image/jpeg" ||
                                (file as File).type === "image/png";
                              if (!isImage) {
                                toast.error(
                                  "Only images (JPEG and PNG) are allowed."
                                );
                                event.currentTarget.value = "";
                              }
                              return isImage;
                            }
                          );
                          form.setFieldValue("images", imageFiles);
                        }
                      }}
                      className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="images"
                  component="div"
                  className="text-red-500"
                />
              </div>

              <div className="w-full font-sans">
                <label
                  htmlFor="weeklyTimeSlots"
                  className="text-lg font-medium"
                >
                  Weekly Time Slots
                </label>
                <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                  {values.weeklyTimeSlots.map((item: any, index: number) => (
                    <div key={item.day} className="mb-4">
                      <div className="rounded-lg border p-4">
                        <div className="flex items-center">
                          <label className="flex items-center">
                            <Field
                              type="checkbox"
                              name={`weeklyTimeSlots[${index}].status`}
                              className="form-checkbox h-5 w-5 rounded-md text-indigo-600"
                              checked={item.status}
                              onChange={(e: any) => {
                                const checked = e.target.checked;
                                setFieldValue(
                                  `weeklyTimeSlots[${index}].status`,
                                  checked
                                );
                                if (!checked) {
                                  setFieldValue(
                                    `weeklyTimeSlots[${index}].openTime`,
                                    ""
                                  );
                                  setFieldValue(
                                    `weeklyTimeSlots[${index}].closeTime`,
                                    ""
                                  );
                                }
                              }}
                            />
                            <span className="ml-2 text-lg">{item.day}:</span>
                          </label>
                        </div>
                        <div className="mt-2 flex flex-col gap-4 md:flex-row">
                          <div className="w-full md:w-1/2 md:flex-grow">
                            <label
                              htmlFor={`weeklyTimeSlots[${index}].openTime`}
                              className="mb-2 md:mb-0 md:mr-2"
                            >
                              Open Time:
                            </label>
                            <DatePicker
                              name={`weeklyTimeSlots[${index}].openTime`}
                              selected={
                                item.status && !item.customTimeSelected
                                  ? new Date(0, 0, 0, 6, 0)
                                  : values.weeklyTimeSlots[index].openTime
                              }
                              onChange={(date) => {
                                setFieldValue(
                                  `weeklyTimeSlots[${index}].openTime`,
                                  date
                                );
                                setFieldValue(
                                  `weeklyTimeSlots[${index}].customTimeSelected`,
                                  true
                                );
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeFormat="HH:mm"
                              timeIntervals={5}
                              dateFormat="HH:mm"
                              className={`w-full rounded-md border ${
                                !values.weeklyTimeSlots[index].status
                                  ? "bg-gray-200"
                                  : "border-gray-300"
                              } px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500`}
                              disabled={!values.weeklyTimeSlots[index].status}
                              required
                              onKeyDown={(e) => e.preventDefault()}
                              autoComplete="off"
                            />
                          </div>
                          <div className="w-full md:w-1/2">
                            <label
                              htmlFor={`weeklyTimeSlots[${index}].closeTime`}
                              className="mb-2 md:mb-0 md:mr-2"
                            >
                              Close Time:
                            </label>
                            <DatePicker
                              name={`weeklyTimeSlots[${index}].closeTime`}
                              selected={
                                item.status && !item.customTimeSelected
                                  ? new Date(0, 0, 0, 18, 0)
                                  : values.weeklyTimeSlots[index].closeTime
                              }
                              onChange={(date) => {
                                setFieldValue(
                                  `weeklyTimeSlots[${index}].closeTime`,
                                  date
                                );
                                setFieldValue(
                                  `weeklyTimeSlots[${index}].customTimeSelected`,
                                  true
                                );
                              }}
                              showTimeSelect
                              minTime={
                                values?.weeklyTimeSlots[index]?.openTime ||
                                new Date(0, 0, 0, 0, 0)
                              }
                              maxTime={
                                new Date(new Date().setHours(23, 59, 59))
                              }
                              timeFormat="HH:mm"
                              timeIntervals={5}
                              dateFormat="HH:mm"
                              showTimeSelectOnly
                              className={`w-full rounded-md border ${
                                !values.weeklyTimeSlots[index].status
                                  ? "bg-gray-200"
                                  : "border-gray-300"
                              } px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500`}
                              disabled={!values.weeklyTimeSlots[index].status}
                              required
                              onKeyDown={(e) => e.preventDefault()}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          name={`weeklyTimeSlots[${index}].openTime`}
                          component="div"
                          className="mt-1 text-red-500"
                        />
                        <ErrorMessage
                          name={`weeklyTimeSlots[${index}].closeTime`}
                          component="div"
                          className="mt-1 text-red-500"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {values.type === "gym" && (
                <div className="mb-4 flex-1">
                  <h2 className="mb-2 text-lg font-semibold">Add Amenities</h2>
                  <div className="flex items-center">
                    <input
                      type="text"
                      placeholder="Add Amenities"
                      value={currentTag}
                      onChange={(e) => setCurrentTag(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleTagAdd(e);
                        }
                      }}
                      className="w-full rounded-md border border-gray-500 bg-gray-100 px-3 py-2 text-gray-800 focus:border-blue-500 focus:outline-none"
                    />
                    <button
                      type="button"
                      className="ml-2 rounded bg-blue-500 px-3 py-2 text-white hover:bg-blue-600"
                      onClick={handleTagAdd}
                    >
                      +
                    </button>
                  </div>
                  <div className="mt-2 space-x-2">
                    {amenities.map((tag, index) => (
                      <span
                        key={index}
                        className="mb-2 mr-2 inline-flex items-center rounded bg-gray-200 px-2 py-1 text-gray-700"
                      >
                        {tag}
                        <button
                          className="ml-2 text-red-500 hover:text-red-600 focus:outline-none"
                          onClick={() => handleTagDelete(index)}
                        >
                          x
                        </button>
                      </span>
                    ))}
                  </div>
                </div>
              )}

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="rounded-md bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-600 focus:outline-none"
                >
                  Add
                </button>
                <button
                  type="button"
                  className="ml-2 rounded-md border border-gray-300 px-4 py-2 focus:outline-none"
                  onClick={handleCancleAddPropertyButton}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddPropertyPage;
