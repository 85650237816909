import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

interface PropertyDetailsTypes {
  name: string;
  type: string;
  size: number;
  price: number;
  description: string;
  location: string;
  images: File[];
  amenities: String[];
}

const PropertyDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const { propertyId } = useParams<{ propertyId: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [propertyDetails, setPropertyDetails] = useState<
    PropertyDetailsTypes | any
  >({});
  const [currentTag, setCurrentTag] = useState("");

  const [deletePropertyIds, setDeletePropertyIds] = useState<string[]>([]);

  const [isEdit, setIsEdit] = useState(false);
  const [selectedImages, setSelectedImages] = useState<any[]>([]);
  const weeklyTimeSlots =
    JSON.parse(
      propertyDetails?.weeklyTimeSlots &&
        propertyDetails?.weeklyTimeSlots !== "-"
        ? propertyDetails?.weeklyTimeSlots
        : "[]"
    ) || [];
  const [currentPropertyType, setCurrentPropertyType] = useState("");

  const initialValues: PropertyDetailsTypes = {
    name: propertyDetails?.name || "",
    type: propertyDetails?.type || "",
    size: propertyDetails?.size || 0,
    price: propertyDetails?.price || 0,
    description: propertyDetails?.description || "",
    location: propertyDetails?.location || "",
    images: selectedImages,
    amenities: propertyDetails?.amenities || [],
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Property Name is required")
      .trim()
      .test(
        "no-multiple-spaces",
        "Property Name should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      )
      .max(40, "Property Name must not exceed 40 characters")
      .matches(
        /^[A-Za-z0-9\s]*$/,
        "Only alphabets and numbers are allowed for the name"
      ),
    type: Yup.string().required("Property Type is required"),
    size: Yup.number()
      .typeError("Size/Area must be a number")
      .required("Size/Area is required")
      .min(0, "Size/Area must be a positive value"),
    price: Yup.number()
      .typeError("Price must be a number")
      .required("Price is required")
      .min(0, "Price must be a positive value"),
    description: Yup.string()
      .required("Description is required")
      .trim()
      .test(
        "no-multiple-spaces",
        "Description should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      ),
    location: Yup.string()
      .required("Location is required")
      .trim()
      .matches(/^[A-Za-z0-9\s]*$/, "No Special Characters are Allowed")
      .test(
        "no-multiple-spaces",
        "Location should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      ),
  });

  const MAX_IMAGES = 5;
  const handleAddImage = (e: any) => {
    const files = e.target.files;
    const tempImages = [...selectedImages];
    const updatedImages = [...propertyDetails.images];

    // Check if the total number of images including the newly added ones is within the limit
    if (propertyDetails.images.length + files.length > MAX_IMAGES) {
      toast.error(`You can only add up to ${MAX_IMAGES} images.`);
      e.target.value = "";
      return;
    }

    // Remove the first selected image from tempImages if it was initially added as a placeholder
    if (tempImages.length > 0 && !tempImages[0].image) {
      tempImages.shift();
    }

    for (const file of files) {
      // Validate the file type
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        toast.error("Only JPEG and PNG image files are allowed.");
        e.target.value = "";
        continue;
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        const image = event.target.result;
        updatedImages.push({ path: image });
      };

      reader.onloadend = () => {
        if (
          updatedImages.length ===
          propertyDetails.images.length + files.length
        ) {
          setPropertyDetails((prevState: any) => ({
            ...prevState,
            images: updatedImages,
          }));
          e.target.value = "";
        }
      };

      reader.readAsDataURL(file);
      tempImages.push({ image: file });
    }

    setSelectedImages(tempImages);
  };

  const handleRemoveImage = (index: number) => {
    setPropertyDetails((prevState: any) => {
      const updatedImages = [...prevState.images];
      const removedImage = updatedImages.splice(index, 1)[0]; // Remove the image from images array

      // If the removed image came from the uploaded images, add its ID to deletePropertyIds
      if (removedImage._id) {
        setDeletePropertyIds((prevIds) => [...prevIds, removedImage._id]);
      }

      return {
        ...prevState,
        images: updatedImages,
      };
    });

    setSelectedImages((prevImages) => {
      const updatedSelectedImages = [...prevImages];
      updatedSelectedImages.splice(index, 1); // Remove the image from selectedImages array
      return updatedSelectedImages;
    });
  };

  const handleTagAdd = (event: any) => {
    event.preventDefault();

    const trimmedTag = currentTag.trim();

    if (trimmedTag !== "") {
      setPropertyDetails((prevState: any) => {
        const updatedAmenities = [...prevState.amenities, trimmedTag];
        return {
          ...prevState,
          amenities: updatedAmenities,
        };
      });
      setCurrentTag("");
    }
  };
  const handleTagDelete = (index: any) => {
    const newTags = [...propertyDetails?.amenities];
    newTags.splice(index, 1);
    setPropertyDetails((prevState: any) => {
      return {
        ...prevState,
        amenities: newTags,
      };
    });
  };

  const handleSubmit = async (values: PropertyDetailsTypes) => {
    const { name, size, price, location, description } = values;

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("size", String(size));
      formData.append("price", String(price));
      formData.append("location", location);
      formData.append("description", description);
      formData.append("createdBy", propertyDetails.createdBy);
      formData.append("weeklyTimeSlots", propertyDetails.weeklyTimeSlots);
      selectedImages.forEach((image: any, index: any) => {
        formData.append(`images`, image?.image);
      });

      propertyDetails?.amenities?.forEach((amenity: any, index: any) => {
        return formData.append(`amenities`, amenity);
      });

      formData.append("deletedImages", JSON.stringify(deletePropertyIds));

      const response = await axios.put(
        `${process.env.REACT_APP_API_AWS_URL}/properties/updateProperty/${propertyId}`,
        formData
      );
      fetchData();
      setIsEdit(false);
      toast(response?.data?.Message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        theme: "light",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    fetchData();
    setIsEdit(false);
  };
  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_AWS_URL}/properties/getProperty/${propertyId}`
      );
      const propertyDetail = response?.data?.Data;
      setPropertyDetails(propertyDetail);
      setCurrentPropertyType(propertyDetail?.type);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, [propertyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEdit = () => {
    setIsEdit(true);
  };

  return (
    <div className="container mx-auto rounded-lg bg-white p-4 shadow-md">
      {isLoading ? (
        <div>
          <div className="overflow-hidden rounded-lg">
            <div className="h-60 w-full animate-pulse bg-gray-200"></div>
          </div>
          <div className="flex flex-col items-center md:items-start">
            <div className="mb-2 h-8 w-3/4 animate-pulse bg-gray-200"></div>
            <div className="mb-2 h-6 w-2/4 animate-pulse bg-gray-200"></div>
            <div className="mb-2 h-4 w-1/4 animate-pulse bg-gray-200"></div>
            <div className="mb-2 h-4 w-1/4 animate-pulse bg-gray-200"></div>
            <div className="mb-2 h-6 w-1/2 animate-pulse bg-gray-200"></div>
            <div className="mb-2 h-6 w-1/2 animate-pulse bg-gray-200"></div>
            <div className="mt-4 h-32 w-full animate-pulse bg-gray-200"></div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          <div className="overflow-hidden rounded-lg">
            <Carousel>
              {propertyDetails?.images?.map((data: any, index: number) => (
                <div key={index}>
                  <img
                    src={data?.path}
                    alt={`Property ${index + 1}`}
                    className="w-full"
                  />
                </div>
              ))}
            </Carousel>
          </div>
          {isEdit ? (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="flex flex-col">
                  <div className="mb-2 w-full">
                    <label htmlFor="name" className="block text-lg font-bold">
                      Property Name:
                    </label>
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      className="mt-2 flex h-12 w-full rounded border border-gray-300 px-4 focus:outline-none"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>
                  <div className="mb-2 w-full">
                    <label htmlFor="size" className="block text-lg font-bold">
                      Size (Sq ft):
                    </label>
                    <Field
                      type="text"
                      id="size"
                      name="size"
                      maxLength={4}
                      className="mt-2 flex h-12 w-full rounded border border-gray-300 px-4 focus:outline-none"
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />

                    <ErrorMessage
                      name="size"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>
                  <div className="mb-2 w-full">
                    <label htmlFor="price" className="block text-lg font-bold">
                      Price:
                    </label>
                    <Field
                      type="text"
                      id="price"
                      name="price"
                      maxLength={5}
                      className="mt-2 flex h-12 w-full rounded border border-gray-300 px-4 focus:outline-none"
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />

                    <ErrorMessage
                      name="price"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>
                  <div className="mb-2 w-full">
                    <label
                      htmlFor="description"
                      className="block text-lg font-bold"
                    >
                      Description:
                    </label>
                    <Field
                      as="textarea"
                      id="description"
                      name="description"
                      className="mt-2 flex h-24 w-full rounded border border-gray-300 px-4 py-2 focus:outline-none"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>
                  <div className="mb-2 w-full">
                    <label
                      htmlFor="location"
                      className="block text-lg font-bold"
                    >
                      Location:
                    </label>
                    <Field
                      type="text"
                      id="location"
                      name="location"
                      className="mt-2 flex h-12 w-full rounded border border-gray-300 px-4 focus:outline-none"
                    />
                    <ErrorMessage
                      name="location"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>
                  <div>
                    <h3 className="mt-4 text-xl font-semibold">Images:</h3>
                    <div className="mt-2 flex flex-wrap">
                      {propertyDetails?.images?.map((data: any, index: any) => (
                        <div key={index} className="w-1/4 p-2">
                          <img
                            src={data?.path}
                            alt="Property"
                            className="w-full"
                          />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(index)}
                            className="mt-2 rounded bg-red-500 px-2 py-1 text-white"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                    </div>
                    <input
                      type="file"
                      accept="image/jpeg, image/png"
                      name="images"
                      onChange={handleAddImage}
                      className="mt-4"
                      multiple
                    />
                  </div>
                  {currentPropertyType === "gym" && (
                    <div className="mb-4 mt-5 flex-1">
                      <h2 className="mb-2 text-lg font-semibold">
                        Update or Add Amenities
                      </h2>
                      <div className="flex items-center">
                        <input
                          type="text"
                          placeholder="Add amenities..."
                          value={currentTag}
                          onChange={(e) => setCurrentTag(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleTagAdd(e);
                            }
                          }}
                          className="w-full rounded-md border border-gray-500 bg-gray-100 px-3 py-2 text-gray-800 focus:border-blue-500 focus:outline-none"
                        />
                        <button
                          type="button"
                          className="ml-2 rounded bg-blue-500 px-3 py-2 text-white hover:bg-blue-600"
                          onClick={handleTagAdd}
                        >
                          +
                        </button>
                      </div>
                      <div className="mt-2 space-x-2">
                        {propertyDetails?.amenities?.map(
                          (tag: any, index: any) => (
                            <span
                              key={index}
                              className="mb-2 mr-2 inline-flex items-center rounded bg-gray-200 px-2 py-1 text-gray-700"
                            >
                              {tag}
                              <button
                                type="button"
                                className="ml-2 text-red-500 hover:text-red-600 focus:outline-none"
                                onClick={() => handleTagDelete(index)}
                              >
                                x
                              </button>
                            </span>
                          )
                        )}
                      </div>
                    </div>
                  )}

                  <div className="mt-4 flex justify-center space-x-4">
                    <button
                      type="submit"
                      className="rounded-lg bg-blue-600 px-6 py-2 text-white hover:bg-blue-700 focus:outline-none"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="rounded-lg bg-red-600 px-6 py-2 text-white hover:bg-red-700 focus:outline-none"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          ) : (
            <>
              <div className="flex flex-col">
                <div className="flex w-full max-w-full items-center justify-between">
                  <h2 className="text-4xl font-bold">
                    {propertyDetails?.name}
                  </h2>
                  <div
                    className="me-5 cursor-pointer text-2xl text-blue-600"
                    onClick={handleEdit}
                  >
                    <BsPencilSquare />
                  </div>
                </div>
                <p className="mb-2 text-xl font-semibold">
                  Type: {propertyDetails?.type}
                </p>
                <p className="mb-2 text-lg">
                  {" "}
                  Size (Sq ft). : {propertyDetails?.size}
                </p>
                <p className="text-2xl font-bold text-green-600">
                  Price / Hour : {propertyDetails?.price}
                </p>
                <p className="mt-4 text-lg text-gray-500">
                  Description : {propertyDetails?.description}
                </p>
                <div className="mt-2 space-x-2">
                  {propertyDetails?.type === "gym" &&
                  propertyDetails?.amenities?.length === 0 ? (
                    <p>No amenities available.</p>
                  ) : (
                    propertyDetails?.amenities?.map((tag: any, index: any) => (
                      <span
                        key={index}
                        className="mb-2 mr-2 inline-flex items-center rounded bg-gray-200 px-2 py-1 text-gray-700"
                      >
                        {tag}
                      </span>
                    ))
                  )}
                </div>
                <div>
                  <h2 className="mb-4 text-xl font-semibold">Opening Hours</h2>
                  <div className="grid grid-cols-2 gap-4">
                    {weeklyTimeSlots?.map((details: any, index: any) => (
                      <div
                        key={index}
                        className={`rounded-md border p-4 ${
                          details?.status ? "bg-green-100" : "bg-red-100"
                        }`}
                      >
                        <p className="font-semibold">{details?.day}</p>
                        <p>
                          {details?.status &&
                            `${details?.openTime} - ${details?.closeTime}`}
                        </p>
                        <p className="text-sm">
                          Status: {details.status ? "Open" : "Closed"}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <button
                  onClick={() => navigate(-1)}
                  className="mt-4 flex items-center justify-center rounded-lg bg-blue-600 px-4 py-2 text-sm font-semibold text-white transition duration-200 hover:bg-blue-700"
                >
                  Back to Property List <span className="ml-2 text-sm">↶</span>
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PropertyDetailsPage;
