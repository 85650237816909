import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { managerList } from "..";

interface AddManagerProps {
  onClose: () => void;
  setData: any;
  fetchData: () => void;
  selectedManager: managerList;
}

const AddManager: FC<AddManagerProps> = ({
  onClose,
  setData,
  fetchData,
  selectedManager,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .trim()
      .matches(/^[a-zA-Z\s]*$/, "Only alphabets are allowed for the name")
      .test(
        "no-multiple-spaces",
        "Name should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      )
      .max(30, "Name must not exceed 30 characters"),
    role: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z\s]*$/, "Only alphabets are allowed for the name")
      .test(
        "no-multiple-spaces",
        "Name should not have multiple spaces",
        (value) => !/\s{2,}/.test(value)
      ),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .test("valid-domain", "Invalid domain name in email", (value) =>
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
      ),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,}$/,
        "Password must contains minimum 6 characters one lowercase, one uppercase, one number and one special case character from @$!%*?&#"
      ),
  });

  const handleSubmit = async (values: any) => {
    const { name, email, password, role, ownerId } = values;
    onClose();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_AWS_URL}/subUser/createSubUsers`,
        {
          name: name,
          email: email,
          password: password,
          role: role,
          ownerId: ownerId,
        }
      );
      const userData = response.data;
      setData((prevData: any) => [...prevData, userData]);
      fetchData();
      toast(response?.data?.Message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error: any) {
      if (error.response?.data?.Status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="fixed inset-0 z-40 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50 lg:z-10">
      <div className="mx-auto w-full max-w-md rounded-lg bg-white p-6 shadow-md">
        <h2 className="mb-4 text-center text-2xl font-semibold">
          Add Manager Details
        </h2>
        <Formik
          initialValues={{
            name: "",
            email: "",
            password: "",
            role: "manager",
            ownerId: selectedManager?._id || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <div className="mb-4">
                <label className="block text-gray-700" htmlFor="name">
                  Name:
                </label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  className={`w-full rounded-md border ${
                    errors.name && touched.name
                      ? "border-red-500"
                      : "border-gray-300"
                  } px-3 py-2 focus:border-blue-500 focus:outline-none`}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="mt-1 text-red-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700" htmlFor="email">
                  Email:
                </label>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  className={`w-full rounded-md border ${
                    errors.email && touched.email
                      ? "border-red-500"
                      : "border-gray-300"
                  } px-3 py-2 focus:border-blue-500 focus:outline-none`}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="mt-1 text-red-500"
                />
              </div>
              {/* <div className="mb-4">
                <label className="block text-gray-700" htmlFor="role">
                  Select Role :
                </label>
                <Field
                  component="select"
                  id="role"
                  name="role"
                  className={`w-full rounded-md border ${
                    errors.role && touched.role
                      ? "border-red-500"
                      : "border-gray-300"
                  } px-3 py-2 focus:border-blue-500 focus:outline-none`}
                >
                  <option value="" disabled>
                    Please Select A Role :
                  </option>
                  <option value="owner">Owner</option>
                  <option value="manager">Manager</option>
                </Field>
                <ErrorMessage
                  name="role"
                  component="div"
                  className="mt-1 text-red-500"
                />
              </div> */}
              <div className="mb-4">
                <label className="block text-gray-700" htmlFor="password">
                  Password:
                </label>
                <div className="relative">
                  <Field
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    className={`w-full rounded-md border ${
                      errors.password && touched.password
                        ? "border-red-500"
                        : "border-gray-300"
                    } px-3 py-2 focus:border-blue-500 focus:outline-none`}
                  />
                  <span
                    className="password-toggle-icon mt-1"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                  </span>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="mt-1 text-red-500"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="rounded-md bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-600 focus:outline-none"
                >
                  Add
                </button>
                <button
                  type="button"
                  className="ml-2 rounded-md border border-gray-300 px-4 py-2 focus:outline-none"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddManager;
