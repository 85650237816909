/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes";
import Logo from "assets/img/layout";
import { useNavigate } from "react-router";

const Sidebar = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props;
  const navigate = useNavigate();
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`my-5 flex items-center justify-center`}>
        <div
          className="text-center font-poppins text-[22px] font-bold uppercase text-navy-700 dark:text-white"
          onClick={() => {
            onClose();
            navigate("/admin/dashboard");
          }}
        >
          <img src={Logo} alt="logo" className="w-50 cursor-pointer p-7" />
        </div>
      </div>
      <div className="mb-7 h-px bg-gray-300 dark:bg-white/30" />

      {/* Nav item */}

      <div className="max-h-[calc(100vh-220px)] overflow-y-auto">
        <ul className="mb-auto whitespace-normal break-words pt-1">
          <Links routes={routes} onClose={onClose} />
        </ul>
      </div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
