import moment from "moment";
import React from "react";
import { FiX } from "react-icons/fi";

interface User {
  _id: any;
  name: string;
  email: string;
  messages: {
    subject: string;
    message: string;
    createdAt: string;
  }[];
}

interface ViewMoreModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: User | null;
}

const ViewMoreModal: React.FC<ViewMoreModalProps> = ({
  isOpen,
  onClose,
  user,
}) => {
  if (!isOpen || !user) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="modal-container w-full max-w-md rounded-lg bg-white shadow-lg">
        <div className="mr-2 flex items-end justify-end lg:mr-5">
          <button
            onClick={onClose}
            className="mt-4 rounded p-2 font-bold text-red-500"
          >
            <FiX className="h-6 w-6" />
          </button>
        </div>
        <div className="modal-content max-h-[600px] overflow-y-auto p-5">
          <h2 className="mb-4 flex justify-center text-2xl font-bold">
            Subject &amp; Message List
          </h2>
          {user.messages.map((message, index) => (
            <div
              key={index}
              className="relative mb-6 border-2 border-[#14AADD] p-3 md:p-4 lg:p-6"
            >
              <div className="mb-2 flex items-start">
                <h3 className="w-1/4 font-bold">Subject:</h3>
                <div className="ml-2 w-3/4 whitespace-normal break-words">
                  {message.subject}
                </div>
              </div>
              <div className="mb-2 flex items-start">
                <h3 className="w-1/4 font-bold">Message:</h3>
                <div className="ml-2 w-3/4 whitespace-normal break-words">
                  {message.message}
                </div>
              </div>
              <div className="justify-left flex text-sm font-medium text-gray-500">
                <span className="mr-2 text-gray-800">Date:</span>
                <span>
                  {moment(message?.createdAt).format("MMMM Do, YYYY")}
                </span>
                <br />
                <span className="mx-2 text-gray-800">Time:</span>
                <span>{moment(message?.createdAt).format("h:mm A")}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewMoreModal;
