// ProfileField.tsx
import React from "react";

interface ProfileFieldProps {
  label: string;
  value?: string;
  children?: React.ReactNode;
}

const ProfileField: React.FC<ProfileFieldProps> = ({
  label,
  value,
  children,
}) => {
  return (
    <div className="mb-4">
      <label className="block text-lg font-bold text-gray-900 dark:text-white">
        {label} :
      </label>
      <p className="mt-1 text-base text-gray-900 dark:text-white">{value}</p>
      {children}
    </div>
  );
};

export default ProfileField;
