const About = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="container mx-auto px-4 py-4 lg:py-8">
        <h1 className="mb-5 text-center text-4xl font-extrabold lg:mb-10">
          About Us
        </h1>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
          <div>
            <div className="mb-8">
              <h4 className="text-left text-xl font-medium text-[#0C0E11]">
                Family Owned And Operated With Real-Life Application, Both As
                Facility Owners And Users
              </h4>
              <p className="text-left text-lg font-normal text-[#6D6E70]">
                As a family-owned and operated business, we bring a unique
                perspective to our services, deeply rooted in real-life
                experiences. Our firsthand knowledge as both facility owners and
                users allows us to understand the practical needs and challenges
                our clients face. This dual perspective ensures that we deliver
                solutions that are not only theoretically sound but also
                practically effective, enhancing the overall experience for
                everyone involved.
              </p>
            </div>
          </div>
          <div>
            <div className="mb-8">
              <h4 className="text-left text-xl font-medium text-[#0C0E11]">
                Books The Official Practice Facility Of The Grand Rapids Gold
                (Denver Nuggets G-League Team)
              </h4>
              <p className="text-left text-lg font-normal text-[#6D6E70]">
                We are proud to serve as the official practice facility for the
                Grand Rapids Gold, the G-League team affiliated with the Denver
                Nuggets. This prestigious partnership highlights our commitment
                to providing top-tier amenities and support for professional
                athletes, fostering an environment where they can train and
                excel. Our facilities meet the high standards required by elite
                teams, ensuring optimal performance and development.
              </p>
            </div>
          </div>
          <div>
            <div className="mb-8">
              <h4 className="text-left text-xl font-medium text-[#0C0E11]">
                Used By Everyone From Elementary Schoolers To High-Level
                Professionals
              </h4>
              <p className="text-left text-lg font-normal text-[#6D6E70]">
                Our facilities are designed to cater to a wide range of users,
                from elementary school students to high-level professionals.
                This inclusive approach ensures that everyone, regardless of age
                or skill level, has access to top-quality resources and support.
                Whether you're just starting out or refining your advanced
                techniques, our facilities provide the perfect environment for
                growth and development.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
