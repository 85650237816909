// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import UserManagement from "views/admin/userManagement";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import { AiFillContacts, AiFillStar, AiTwotoneBell } from "react-icons/ai";
import {
  MdAreaChart,
  MdBarChart,
  MdHome,
  MdLock,
  MdManageAccounts,
  MdOutlineLocationCity,
  MdPerson,
} from "react-icons/md";
import {
  RiBillFill,
  RiHistoryLine,
  RiMoneyDollarCircleLine,
} from "react-icons/ri";
import RatingAndReviewManagement from "views/admin/RatingAndReview";
import BookingManagementList from "views/admin/bookingManagement";
import ContactUsManagementList from "views/admin/contactUsManagement";
import ManagerManagementList from "views/admin/managerManagement";
import NotificationManagement from "views/admin/notificationManagment";
import OwnerManagementList from "views/admin/ownerManagement";
import AddPropertyPage from "views/admin/ownerManagement/components/AddPropertyPage";
import PropertyDetailsPage from "views/admin/propertyDetails";
import PropertyManagement from "views/admin/propertyManagement";
import About from "views/auth/AboutUs";
import ForgotPassword from "views/auth/ForgotPassword";
import PrivacyPolicy from "views/auth/PrivacyPolicy";
import ResetPassword from "views/auth/ResetPassword";
import Terms from "views/auth/TermsAndCondition";
import RevenueSharing from "views/admin/revenueSharing";
import TransactionHistory from "views/admin/TransactionHistory";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "User Management",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "user-management",
    component: <UserManagement />,
  },
  {
    name: "Property Management",
    layout: "/admin",
    icon: <MdAreaChart className="h-6 w-6" />,
    path: "property-management",
    component: <PropertyManagement />,
  },
  {
    name: "Property Details",
    layout: "/admin",
    icon: <MdAreaChart className="h-6 w-6" />,
    path: "property-management/property-details/:propertyId",
    component: <PropertyDetailsPage />,
    isPublic: true,
  },
  {
    name: "Property Details",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "properties/getProperty/:propertyId",
    component: <PropertyDetailsPage />,
    isPublic: true,
  },
  {
    name: "For Add New Property",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "owner-list/properties/addProperty/:propertyId",
    component: <AddPropertyPage />,
    isPublic: true,
  },
  {
    name: "Manager Management",
    layout: "/admin",
    path: "manager-list",
    icon: <MdManageAccounts className="h-6 w-6" />,
    component: <ManagerManagementList />,
  },
  {
    name: "Owners Management",
    layout: "/admin",
    path: "owner-list",
    icon: <MdOutlineLocationCity className="h-6 w-6" />,
    component: <OwnerManagementList />,
  },
  {
    name: "Bookings",
    layout: "/admin",
    icon: <RiBillFill className="h-6 w-6" />,
    path: "booking-management",
    component: <BookingManagementList />,
  },
  {
    name: "Notification Management",
    layout: "/admin",
    icon: <AiTwotoneBell className="h-6 w-6" />,
    path: "notification-management",
    component: <NotificationManagement />,
  },
  {
    name: "Rating & Review",
    layout: "/admin",
    path: "rating-review-management",
    icon: <AiFillStar className="h-6 w-6" />,
    component: <RatingAndReviewManagement />,
  },
  {
    name: "Contact Us Management",
    layout: "/admin",
    icon: <AiFillContacts className="h-6 w-6" />,
    path: "contact-us-management",
    component: <ContactUsManagementList />,
  },
  {
    name: "Revenue Sharing",
    layout: "/admin",
    icon: <RiMoneyDollarCircleLine className="h-6 w-6" />,
    path: "revenue-sharing",
    component: <RevenueSharing />,
  },
  {
    name: "Transaction History",
    layout: "/admin",
    icon: <RiHistoryLine className="h-6 w-6" />,
    path: "transaction-history",
    component: <TransactionHistory />,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
    isPublic: true,
  },
  {
    name: "forgot",
    layout: "/auth",
    path: "forgot-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgotPassword />,
    isPublic: true,
  },
  {
    name: "reset",
    layout: "/auth",
    path: "reset-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ResetPassword />,
    isPublic: true,
  },
  {
    name: "about",
    layout: "/auth",
    path: "about",
    icon: <MdLock className="h-6 w-6" />,
    component: <About />,
    isPublic: true,
  },
  {
    name: "terms",
    layout: "/auth",
    path: "terms",
    icon: <MdLock className="h-6 w-6" />,
    component: <Terms />,
    isPublic: true,
  },
  {
    name: "privacy",
    layout: "/auth",
    path: "privacy",
    icon: <MdLock className="h-6 w-6" />,
    component: <PrivacyPolicy />,
    isPublic: true,
  },
];

export default routes;
