import axios from "axios";
import FilterComponent from "components/search";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { HiOutlineViewGrid } from "react-icons/hi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TableColumn } from "react-data-table-component";
import "./Table.css";
import ReactLoading from "react-loading";

interface PropertyDataTypes {
  _id: number;
  name: string;
  type: string;
  location: string;
  price: string;
  size: string;
}

const paginationOptions = {
  rowsPerPageText: "Items Per Page:",
  rangeSeparatorText: "of",
  selectAllRowsItem: true,
  selectAllRowsItemText: "All",
};

const caseInsensitiveSort = (rowA: any, rowB: any) => {
  const nameA = rowA.name.toLowerCase();
  const nameB = rowB.name.toLowerCase();
  const typeA = rowA.type.toLowerCase();
  const typeB = rowB.type.toLowerCase();

  return nameA.localeCompare(nameB) || typeA.localeCompare(typeB);
};

const PropertyManagement: FC<any> = () => {
  const [filterText, setFilterText] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<PropertyDataTypes[]>([]);
  const navigate = useNavigate();

  const showDeleteConfirmationModal = (id: number) => {
    setDeleteUserId(id);
    setShowDeleteConfirmation(true);
  };

  const hideDeleteConfirmationModal = () => {
    setShowDeleteConfirmation(false);
    setDeleteUserId(null);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_AWS_URL}/properties/getProperties`
      );
      setData(response?.data?.Data);
    } catch (error: any) {
      if (error.response?.data?.status === 404) {
        toast(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("Page not found!");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteConfirmation = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_AWS_URL}/properties/deleteProperty/${deleteUserId}`
      );
      toast(response?.data?.Message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      fetchData();
      setShowDeleteConfirmation(false);
    } catch (error: any) {
      toast(error?.response?.data?.Message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const propertyDetails = (id: number) => {
    navigate(`property-details/${id}`);
  };

  const columns: TableColumn<PropertyDataTypes>[] = [
    {
      name: "Property Name",
      selector: (row: { name: string }) => row.name,
      sortable: true,
      id: "propertyName",
      sortFunction: caseInsensitiveSort,
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
    {
      name: "Type",
      selector: (row: { type: string }) => row.type,
      sortable: true,
      id: "type",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
      },
    },
    {
      name: "Location",
      selector: (row: { location: string }) => row.location,
      id: "location",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
      },
    },
    {
      name: "Price / Hour",
      selector: (row: { price: string }) => row.price,
      id: "price",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
      },
    },
    {
      name: "Size (sq ft)",
      selector: (row: { size: string }) => row.size,
      id: "size",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
      },
    },
    {
      name: "Manage Property",
      id: "viewProperty",
      cell: (row: { _id: number }) => (
        <div className="flex">
          <button
            // onClick={() => propertyDetails(row._id)}
            onClick={() => propertyDetails(row._id)}
            className="mr-2 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          >
            <HiOutlineViewGrid />
          </button>
          <button
            onClick={() => showDeleteConfirmationModal(row._id)}
            className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
          >
            <RiDeleteBin5Line />
          </button>
        </div>
      ),
    },
  ];

  const filteredItems = useMemo(() => {
    return data?.filter((item) => {
      const nameMatch =
        item?.name &&
        item?.name?.toLowerCase()?.includes(filterText.toLowerCase());
      const locationMatch =
        item?.location &&
        item?.location?.toLowerCase()?.includes(filterText.toLowerCase());
      return nameMatch || locationMatch;
    });
  }, [data, filterText]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e: ChangeEvent<HTMLInputElement>) =>
          setFilterText(e.target.value)
        }
        filterText={filterText}
      />
    );
  }, [filterText]);

  return (
    <>
      {showDeleteConfirmation && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure you want to delete the property?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleDeleteConfirmation}
                className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
              >
                Yes
              </button>
              <button
                onClick={hideDeleteConfirmationModal}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="property-management-table-header">
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={
            <ReactLoading
              type={"cylon"}
              color={"#14aadd"}
              height={80}
              width={80}
            />
          }
        />
      </div>
    </>
  );
};

export default PropertyManagement;
