import React, { useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import AppRoutes from "routes";
import axios from "axios";
import { toast } from "react-toastify";

export default function Admin(props: { [x: string]: any }) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(AppRoutes);
  }, [location.pathname]);

  const getActiveRoute = (routes: RoutesType[]): string | boolean => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";

  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

  const Id = localStorage.getItem("id");

  const showDeleteConfirmationModal = () => {
    setShowLogoutConfirmation(true);
  };

  const hideDeleteConfirmationModal = () => {
    setShowLogoutConfirmation(false);
  };

  const handleLogoutButton = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_AWS_URL}/logout`,
        {
          id: Id,
        }
      );
      toast(response?.data?.Message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      localStorage.clear();
      window.location.replace("/auth/sign-in");
    } catch (error: any) {
      if (error?.response?.data?.Status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  return (
    <div className="flex h-full w-full">
      <Sidebar
        open={open}
        onClose={() =>
          window && window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
        }
      />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              brandText={currentRoute}
              secondary={getActiveNavbar(AppRoutes)}
              showDeleteConfirmationModal={showDeleteConfirmationModal}
              {...rest}
            />
            <>
              {showLogoutConfirmation && (
                <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
                  <div className="rounded bg-white p-6">
                    <p>Are you sure you want to Logout?</p>
                    <div className="mt-4 flex justify-center">
                      <button
                        onClick={handleLogoutButton}
                        className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
                      >
                        Yes
                      </button>
                      <button
                        onClick={hideDeleteConfirmationModal}
                        className="rounded bg-gray-500 px-4 py-2 text-white"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
            <div className="mx-auto mb-auto mt-2 h-full min-h-[80vh] p-2 pt-5 md:pr-2">
              <Routes>
                {getRoutes(AppRoutes)}

                <Route
                  path="/"
                  element={<Navigate to="/admin/dashboard" replace />}
                />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
