import React from "react";
import { managerList } from "..";

interface ViewMoreModalProps {
  isOpen: boolean;
  onClose: () => void;
  managerList: Array<managerList>;
  selectedManager: managerList;
}

const ViewManagersModal: React.FC<ViewMoreModalProps> = ({
  isOpen,
  onClose,
  managerList,
  selectedManager,
}) => {
  if (!isOpen || !managerList) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="modal-container w-full max-w-md rounded-lg bg-white shadow-lg">
        <div className="flex items-end justify-end p-4 lg:pr-6">
          <button
            onClick={onClose}
            className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="modal-content max-h-[600px] overflow-y-auto p-5">
          <h2 className="mb-4 text-center text-xl font-semibold text-gray-800">
            {selectedManager.name}'s Managers
          </h2>
          {managerList.map((item, index) => (
            <div
              key={index}
              className="mb-6 rounded-lg bg-white shadow-sm transition duration-300 ease-in-out hover:shadow-md"
            >
              <div className="p-6">
                <div className="mb-4">
                  <h3 className="text-sm font-semibold text-gray-600">Name</h3>
                  <p className="mt-1 text-lg font-medium">{item.name}</p>
                </div>
                <div>
                  <h3 className="text-sm font-semibold text-gray-600">Email</h3>
                  <p className="mt-1 text-lg font-medium">{item.email}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewManagersModal;
