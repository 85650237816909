import { ChangeEvent, FC, useMemo, useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { TableColumn } from "react-data-table-component";
import FilterComponent from "components/search";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import "./Table.css";

interface Rating {
  _id: any;
  user: string;
  propertyType: string;
  comment: string;
  date: string;
  rating: number;
  propertyName: string;
}
const paginationOptions = {
  rowsPerPageText: "Items Per Page:",
  rangeSeparatorText: "of",
  selectAllRowsItem: true,
  selectAllRowsItemText: "All",
};
const RatingAndReviewManagement: FC<any> = () => {
  const [filterText, setFilterText] = useState("");
  const [data, setData] = useState<Rating[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_AWS_URL}/properties/getAllPropertyReviewsAndAverageRatingsForAdmin`
      );
      setData(response?.data?.Data?.reviews);
    } catch (error: any) {
      if (error.response?.data?.status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns: TableColumn<Rating>[] = [
    {
      name: "User Name",
      selector: (row: Rating) => row.user,
      sortable: true,
      id: "user",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
    {
      name: "Property Name",
      selector: (row: Rating) => row.propertyName,
      sortable: true,
      id: "user",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
    {
      name: "Facility Type",
      selector: (row: Rating) => row.propertyType,
      sortable: true,
      id: "propertyType",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
    {
      name: "Rating",
      selector: (row: Rating) => row.rating,
      sortable: true,
      id: "rating",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
      },
    },
    {
      name: "Review",
      selector: (row: Rating) => row.comment,
      sortable: true,
      id: "comment",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
      },
      cell: (row: Rating) => (
        <div className="text-wrap-cell">{row.comment}</div>
      ),
    },
    {
      name: "Date",
      selector: (row: Rating) => row.date,
      sortable: true,
      id: "date",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
      format: (row: { date: string }) => {
        const formattedDate = moment(
          row.date,
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        ).format("YYYY-MM-DD");
        return moment(formattedDate, "DD-MM-YYYY").isValid()
          ? formattedDate
          : "Invalid Date";
      },
    },
  ];

  const filteredItems = useMemo(() => {
    return data?.filter((item) => {
      const facilityTypeMatch =
        item?.propertyType &&
        item?.propertyType?.toLowerCase()?.includes(filterText?.toLowerCase());
      const userMatch =
        item?.user &&
        item?.user?.toLowerCase().includes(filterText?.toLowerCase());
      const commentMatch =
        item?.comment &&
        item?.comment?.toLowerCase().includes(filterText?.toLowerCase());
      const propertyName =
        item?.propertyName &&
        item?.propertyName?.toLowerCase().includes(filterText?.toLowerCase());

      return facilityTypeMatch || commentMatch || userMatch || propertyName;
    });
  }, [filterText, data]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e: ChangeEvent<HTMLInputElement>) =>
          setFilterText(e.target.value)
        }
        filterText={filterText}
      />
    );
  }, [filterText]);

  return (
    <>
      <div className="rating-and-review-management-table-header">
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          paginationComponentOptions={paginationOptions}
          persistTableHead
          progressPending={loading}
          progressComponent={
            <ReactLoading
              type={"cylon"}
              color={"#14aadd"}
              height={80}
              width={80}
            />
          }
        />
      </div>
    </>
  );
};

export default RatingAndReviewManagement;
