import axios from "axios";
import ReactLoading from "react-loading";
import Widget from "components/widget/Widget";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { AiOutlineUser } from "react-icons/ai";
import { FaBuildingUser, FaPeopleGroup } from "react-icons/fa6";
import { IoIosBasketball, IoIosFitness } from "react-icons/io";
import { toast } from "react-toastify";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_AWS_URL}/dashboard/fetchDashboardCounts`;
    await axios
      .get(apiUrl)
      .then((response) => {
        setDashboardData(response?.data?.Data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        console.log(
          "error?.response?.data?.Message",
          error?.response?.data?.Message
        );
        toast.error("An error occurred while fetching data.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="p-6">
      {loading ? (
        <div className="flex min-h-[60vh] items-center justify-center text-center">
          <ReactLoading
            type={"spin"}
            color={"#14aadd"}
            height={60}
            width={60}
          />
        </div>
      ) : dashboardData ? (
        <div>
          <div className="mb-6 flex flex-wrap justify-center gap-5 md:justify-between">
            <Widget
              icon={<FaPeopleGroup className="h-7 w-7" />}
              title={"Total Users"}
              subtitle={`${dashboardData.user}`}
            />

            <Widget
              icon={<AiOutlineUser className="h-6 w-6" />}
              title={"Total Managers"}
              subtitle={`${dashboardData.manager}`}
            />

            <Widget
              icon={<FaBuildingUser className="h-7 w-7" />}
              title={"Total Owners"}
              subtitle={`${dashboardData.owner}`}
            />

            <Widget
              icon={<IoIosBasketball className="h-6 w-6" />}
              title={"Total Basketball Properties"}
              subtitle={`${dashboardData.basketball}`}
            />

            <Widget
              icon={<IoIosFitness className="h-6 w-6" />}
              title={"Total Gym Properties"}
              subtitle={`${dashboardData.gym}`}
            />
          </div>

          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div className="rounded-lg bg-white p-6 shadow-lg">
              <h2 className="mb-4 text-2xl font-bold text-gray-800">Users</h2>
              <ReactApexChart
                options={{
                  chart: { id: "role-chart" },
                  xaxis: { categories: ["Users", "Managers", "Owners"] },
                  colors: ["#4caf50"],
                }}
                series={[
                  {
                    name: "Counts",
                    data: [
                      dashboardData.user,
                      dashboardData.manager,
                      dashboardData.owner,
                    ],
                  },
                ]}
                type="bar"
                height={350}
              />
            </div>

            <div className="rounded-lg bg-white p-6 shadow-lg">
              <h2 className="mb-4 text-2xl font-bold text-gray-800">
                Properties
              </h2>
              <ReactApexChart
                options={{
                  chart: { id: "property-chart" },
                  xaxis: { categories: ["Basketball", "Gym"] },
                  colors: ["#2196f3"],
                }}
                series={[
                  {
                    name: "Counts",
                    data: [dashboardData.basketball, dashboardData.gym],
                  },
                ]}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex min-h-[60vh] items-center justify-center text-center">
          <h1 className="text-2xl font-bold text-red-500">No Data Found</h1>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
