import { ChangeEvent, FC } from "react";
import { FiSearch } from "react-icons/fi";

interface FilterComponentProps {
  onFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeDetails?: () => void;
  filterText: string;
  addText?: string;
  icon?: any;
}

const FilterComponent: FC<FilterComponentProps> = ({
  onFilter,
  handleChangeDetails,
  filterText,
  addText,
  icon,
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (/^[^a-zA-Z0-9]/.test(inputValue)) {
      event.target.value = inputValue.substring(1);
    }

    onFilter(event);
  };

  return (
    <div className="my-5 flex flex-row items-center space-x-0 sm:flex-col sm:space-x-2">
      {handleChangeDetails && (
        <div className="mr-4 flex items-center lg:mr-0">
          <p className="hidden text-center text-sm font-semibold sm:inline-block md:mr-4 lg:text-xl">
            {addText}
          </p>
          <button
            className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 text-white hover:bg-blue-700"
            onClick={handleChangeDetails}
          >
            {icon}
          </button>
        </div>
      )}
      <div
        className="group my-5 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 focus-within:outline-none focus-within:ring dark:bg-navy-900 dark:text-white xl:w-[225px]"
        style={{ width: "fit-content" }}
      >
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          value={filterText}
          placeholder="Search..."
          onChange={handleInputChange}
          className="m-3 block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
        />
      </div>
    </div>
  );
};

export default FilterComponent;
