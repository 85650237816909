import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { toast } from "react-toastify";
import Logo from "assets/img/layout";

const SignIn = () => {
  interface FormValues {
    email: string;
    password: string;
    keepLoggedIn: boolean;
  }

  const initialValues: FormValues = {
    email: "",
    password: "",
    keepLoggedIn: false,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .test("valid-domain", "Invalid domain name in email", (value) =>
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
      ),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,}$/,
        "Password must contains minimum 6 characters one lowercase, one uppercase, one number and one special case character from @$!%*?&#"
      ),
  });

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_AWS_URL}/login`,
        {...values, role: 'admin'}
      );
      localStorage.setItem("accessToken", response?.data?.token);
      localStorage.setItem("id", response?.data?.id);
      window.location.replace("/admin/dashboard");
      toast(response?.data?.Message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error: any) {
      if (error.response?.data?.status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }

    setLoading(false);
    setSubmitting(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2">
      <div className="mt-[10vh] w-full max-w-[500px] flex-col items-center md:pl-4">
        <div className="form-container lg mx-auto w-full p-6 dark:bg-navy-900 md:w-[360px]">
          <div className="mb-5 flex items-center justify-center text-center font-poppins text-[22px] font-bold uppercase text-navy-700 dark:text-white">
            <img src={Logo} alt="logo" className="w-25 align-middle" />
          </div>
          <h4 className="mb-2.5 text-center text-4xl font-bold text-navy-700">
            Welcome Back!
          </h4>
          <p className="mb-9 ml-1 text-center text-base font-normal text-gray-600">
            Enter your email and password to sign in!
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your Email"
                  className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none focus:border-gray-700 dark:!border-white/10 dark:text-white"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-sm text-red-500"
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="relative">
                  <Field
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    autoComplete="password"
                    placeholder="Min. 6 characters"
                    className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none  focus:border-gray-700 dark:!border-white/10 dark:text-white"
                  />
                  <span
                    className="password-toggle-icon mt-1"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                  </span>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-sm text-red-500"
                />
              </div>

              <div className="mb-4 flex items-center justify-between px-2">
                <div className="flex items-center">
                  <Field
                    type="checkbox"
                    id="keepLoggedIn"
                    name="keepLoggedIn"
                    className="mr-2"
                  />
                  <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                    Keep me logged In
                  </p>
                </div>
                <Link
                  to="/auth/forgot-password"
                  className="text-sm font-medium text-[#06A7E0]"
                >
                  Forgot Password?
                </Link>
              </div>
              <button
                type="submit"
                className="linear mt-2 w-full rounded-xl bg-[#14AADD]  py-[12px] text-base font-medium text-white transition duration-200 hover:bg-[#14AADD]  active:bg-[#14AADD]  dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                disabled={loading}
              >
                Login
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
