import React from "react";

const Terms = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="container mx-auto px-4 py-4 lg:py-8">
        <h1 className="mb-5 text-center text-4xl font-extrabold lg:mb-10">
          Terms & Conditions
        </h1>
        <p className="mb-8 text-lg font-normal text-gray-900">
          These terms and conditions (the "Terms and Conditions") govern the use
          of www.hoopgng.com (the "Site"). This Site is owned and operated by
          Hoopgng. This Site is an ecommerce website. By using this Site, you
          indicate that you have read and understand these Terms and Conditions
          and agree to abide by them at all times.
        </p>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
          <div>
            <h4 className="text-decoration mb-8 text-xl font-medium text-gray-900 underline">
              Intellectual Property
            </h4>
            <p className="mb-8 text-lg font-normal text-gray-900">
              All content published and made available on our Site is the
              property of Hoopgng and the Site's creators. This includes, but is
              not limited to images, text, logos, documents, downloadable files
              and anything that contributes to the composition of our Site.
            </p>
          </div>
          <div>
            <h4 className="text-decoration mb-8 text-xl font-medium text-gray-900 underline">
              Accounts
            </h4>
            <p className="mb-8 text-lg font-normal text-gray-900">
              When you create an account on our Site, you agree to the
              following:
            </p>
            <p className="mb-8 text-lg font-normal text-gray-900">
              1. You are solely responsible for your account and the security
              and privacy of your account, including passwords or sensitive
              information attached to that account; and
            </p>
            <p className="mb-8 text-lg font-normal text-gray-900">
              2. All personal information you provide to us through your account
              is up to date, accurate, and truthful and that you will update
              your personal information if it changes.
            </p>
            <p className="mb-8 text-lg font-normal text-gray-900">
              We reserve the right to suspend or terminate your account if you
              are using our Site illegally or if you violate these Terms and
              Conditions.
            </p>
          </div>
          <div>
            <h4 className="text-decoration mb-8 text-xl font-medium text-gray-900 underline">
              Sale of Services
            </h4>
            <p className="mb-8 text-lg font-normal text-gray-900">
              These Terms and Conditions govern the sale of services available
              on our Site. The following services are available on our Site:
              <br /> <br /> - Gym Rental. <br /> <br /> - Basketball Court
              <br /> <br /> The services will be paid for in full when the
              services are ordered. These Terms and Conditions apply to all the
              services that are displayed on our Site at the time you access it.
              All information, descriptions, or images that we provide about our
              services are as accurate as possible. However, we are not legally
              bound by such information, descriptions, or images as we cannot
              guarantee the accuracy of all services we provide. You agree to
              purchase services from our Site at your own risk. <br /> <br /> We
              reserve the right to modify, reject or cancel your order whenever
              it becomes necessary. If we cancel your order and have already
              processed your payment, we will give you a refund equal to the
              amount you paid. You agree that it is your responsibility to
              monitor your payment instrument to verify receipt of any refund.
            </p>
          </div>
          <div>
            <h4 className="text-decoration mb-8 text-xl font-medium text-gray-900 underline">
              Payments
            </h4>
            <p className="mb-8 text-lg font-normal text-gray-900">
              We accept the following payment methods on our Site:
              <br /> <br /> - Credit Card; <br /> <br />
              - PayPal; and <br /> <br />
              - Debit. <br /> <br />
              When you provide us with your payment information, you authorize
              our use of and access to the payment instrument you have chosen to
              use. By providing us with your payment information, you authorize
              us to charge the amount due to this payment instrument. If we
              believe your payment has violated any law or these Terms and
              Conditions, we reserve the right to cancel or reverse your
              transaction
            </p>
          </div>
          <div>
            <h4 className="text-decoration mb-8 text-xl font-medium text-gray-900 underline">
              Consumer Protection Law
            </h4>
            <p className="mb-8 text-lg font-normal text-gray-900">
              Where any consumer protection legislation in your jurisdiction
              applies and cannot be excluded, these Terms and Conditions will
              not limit your legal rights and remedies under that legislation.
              These Terms and Conditions will be read subject to the mandatory
              provisions of that legislation. If there is a conflict between
              these Terms and Conditions and that legislation, the mandatory
              provisions of the legislation will apply.
            </p>
          </div>
          <div>
            <h4 className="text-decoration mb-8 text-xl font-medium text-gray-900 underline">
              Limitation of Liability
            </h4>
            <p className="mb-8 text-lg font-normal text-gray-900">
              Hoopgng and our directors, officers, agents, employees,
              subsidiaries, and affiliates will not be liable for any actions,
              claims, losses, damages, liabilities and expenses including legal
              fees from your use of the Site.
            </p>
          </div>
          <div>
            <h4 className="text-decoration mb-8 text-xl font-medium text-gray-900 underline">
              Indemnity
            </h4>
            <p className="mb-8 text-lg font-normal text-gray-900">
              Except where prohibited by law, by using this Site you indemnify
              and hold harmless Hoopgng and our directors, officers, agents,
              employees, subsidiaries, and affiliates from any actions, claims,
              losses, damages, liabilities and expenses including legal fees
              arising out of your use of our Site or your violation of these
              Terms and Conditions.
            </p>
          </div>
          <div>
            <h4 className="text-decoration mb-8 text-xl font-medium text-gray-900 underline">
              Applicable Law
            </h4>
            <p className="mb-8 text-lg font-normal text-gray-900">
              These Terms and Conditions are governed by the laws of the State
              of Michigan.
            </p>
          </div>
          <div>
            <h4 className="text-decoration mb-8 text-xl font-medium text-gray-900 underline">
              Severability
            </h4>
            <p className="mb-8 text-lg font-normal text-gray-900">
              If at any time any of the provisions set forth in these Terms and
              Conditions are found to be inconsistent or invalid under
              applicable laws, those provisions will be deemed void and will be
              removed from these Terms and Conditions. All other provisions will
              not be affected by the removal and the rest of these Terms and
              Conditions will still be considered valid.
            </p>
          </div>
          <div>
            <h4 className="text-decoration mb-8 text-xl font-medium text-gray-900 underline">
              Changes
            </h4>
            <p className="mb-8 text-lg font-normal text-gray-900">
              These Terms and Conditions may be amended from time to time in
              order to maintain compliance with the law and to reflect any
              changes to the way we operate our Site and the way we expect users
              to behave on our Site. We will notify users by email of changes to
              these Terms and Conditions or post a notice on our Site.
            </p>
          </div>
          <div>
            <h4 className="text-decoration mb-8 text-xl font-medium text-gray-900 underline">
              Contact Details
            </h4>
            <p className="mb-8 text-lg font-normal text-gray-900">
              Please contact us if you have any questions or concerns. Our
              contact details are as follows: <br /> <br /> (616) 915-9031
              <br />
              hoopgng1@gmail.com <br />
              4195 East Paris Ave SE, Grand Rapids, MI 49512 <br /> <br />
              You can also contact us through the feedback form available on our
              Site. Effective Date: 20th day of March, 2024
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
