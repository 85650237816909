import { ChangeEvent, FC, useMemo, useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { TableColumn } from "react-data-table-component";
import FilterComponent from "components/search";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import "./Table.css";

interface Transaction {
  TransactionId: any;
  Amount: string;
  Status: string;
  Sender: string;
  Receiver: string;
  CreateTime: string;
}
const paginationOptions = {
  rowsPerPageText: "Items Per Page:",
  rangeSeparatorText: "of",
  selectAllRowsItem: true,
  selectAllRowsItemText: "All",
};
const TransactionHistory: FC<any> = () => {
  const [filterText, setFilterText] = useState("");
  const [data, setData] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_AWS_URL}/properties/getTransactionHistory`
      );
      setData(response?.data?.Data);
    } catch (error: any) {
      if (error.response?.data?.status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns: TableColumn<Transaction>[] = [
    {
      name: "Transaction Id",
      selector: (row: Transaction) => row.TransactionId,
      sortable: true,
      id: "transactionId",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
    {
      name: "Amount",
      selector: (row: Transaction) => row.Amount,
      sortable: true,
      id: "amount",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
    {
      name: "Status",
      selector: (row: Transaction) => row.Status,
      sortable: true,
      id: "status",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
    {
      name: "Sender",
      selector: (row: Transaction) => row.Sender,
      sortable: true,
      id: "sender",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
      },
    },
    {
      name: "Receiver",
      selector: (row: Transaction) => row.Receiver,
      sortable: true,
      id: "reciver",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
      },
    },
    {
      name: "Date",
      selector: (row: Transaction) => row.CreateTime,
      sortable: true,
      id: "createTime",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
      format: (row: { CreateTime: string }) => {
        const formattedDate = moment(
          row.CreateTime,
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        ).format("YYYY-MM-DD");
        return moment(formattedDate, "DD-MM-YYYY").isValid()
          ? formattedDate
          : "Invalid Date";
      },
    },
  ];

  const filteredItems = useMemo(() => {
    return data?.filter((item) => {
      const amountMatch =
        item?.Amount &&
        item?.Amount?.toLowerCase()?.includes(filterText?.toLowerCase());
      const transactionIdMatch =
        item?.TransactionId &&
        item?.TransactionId?.toLowerCase().includes(filterText?.toLowerCase());
      return amountMatch || transactionIdMatch;
    });
  }, [filterText, data]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e: ChangeEvent<HTMLInputElement>) =>
          setFilterText(e.target.value)
        }
        filterText={filterText}
      />
    );
  }, [filterText]);

  return (
    <>
      <div className="transaction-history-table">
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          paginationComponentOptions={paginationOptions}
          persistTableHead
          progressPending={loading}
          progressComponent={
            <ReactLoading
              type={"cylon"}
              color={"#14aadd"}
              height={80}
              width={80}
            />
          }
        />
      </div>
    </>
  );
};

export default TransactionHistory;
