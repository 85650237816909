import ReactLoading from "react-loading";
import axios from "axios";
import FilterComponent from "components/search";
import moment from "moment";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineUserAdd } from "react-icons/ai";
import { BsFillHouseAddFill, BsPersonFillAdd } from "react-icons/bs";
import { MdEditSquare } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { toast } from "react-toastify";
import "./Table.css";
import AddManager from "./components/AddManager";
import AddOwner from "./components/AddOwner";
import EditModal from "./components/EditModal";
import ViewManagersModal from "./components/ViewManagersModal";
import ViewPropertyModal from "./components/ViewPropertyModal";
import { useNavigate } from "react-router-dom";
import { TableColumn } from "react-data-table-component";

export interface managerList {
  _id: String;
  name: String;
  email: String;
  role: String;
  ownerId: String;
}

export interface PropertyListTypes {
  _id: String;
  name: String;
  type: String;
}

interface OwnerType {
  _id: any;
  name: string;
  email: string;
  password: string;
  role: string;
  ownerId: string;
  DOB: string;
}

const paginationOptions = {
  rowsPerPageText: "Items Per Page:",
  rangeSeparatorText: "of",
  selectAllRowsItem: true,
  selectAllRowsItemText: "All",
};

const dateSort = (a: OwnerType, b: OwnerType) => {
  const dateA = moment(a.DOB, "DD/MM/YYYY").toDate();
  const dateB = moment(b.DOB, "DD/MM/YYYY").toDate();
  return dateA.getTime() - dateB.getTime();
};

const caseInsensitiveSort = (rowA: OwnerType, rowB: OwnerType) => {
  const nameA = rowA.name.toLowerCase();
  const nameB = rowB.name.toLowerCase();
  const emailA = rowA.email.toLowerCase();
  const emailB = rowB.email.toLowerCase();

  if (nameA === nameB && emailA === emailB) {
    return 0;
  }

  return (
    nameA.localeCompare(nameB, undefined, { sensitivity: "base" }) ||
    emailA.localeCompare(emailB, undefined, { sensitivity: "base" })
  );
};

const OwnerManagementList: FC<any> = () => {
  const [filterText, setFilterText] = useState("");
  const [selectedUser, setSelectedUser] = useState<OwnerType | null>(null);
  const [editDetails, setEditDetails] = useState(false);
  const [addUserDetails, setAddUserDetails] = useState(false);
  const [data, setData] = useState<OwnerType[]>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState<number | null>(null);

  const [isViewManagerModalOpen, setIsViewManagerModalOpen] = useState(false);
  const [isViewPropertyModalOpen, setIsPropertyModalOpen] = useState(false);
  const [isAddManagerModalOpen, setIsAddManagerModalOpen] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);

  const [managerList, setManagerList] = useState([]);
  const [propertyList, setPropertyList] = useState([]);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const showDeleteConfirmationModal = (id: number) => {
    setDeleteUserId(id);
    setShowDeleteConfirmation(true);
  };

  const hideDeleteConfirmationModal = () => {
    setShowDeleteConfirmation(false);
    setDeleteUserId(null);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_AWS_URL}/deleteOwner/${deleteUserId}`
      );
      toast(response?.data?.Message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      fetchData();
      hideDeleteConfirmationModal();
    } catch (error: any) {
      if (error.response?.data?.status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_AWS_URL}/getOwners`
      );
      setData(response?.data?.Data);
    } catch (error: any) {
      if (error.response?.data?.status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (id: number) => {
    const user = data.find((user) => user._id === id);
    setSelectedUser(user);
    setEditDetails(true);
  };

  const fetchPropertyList = async (id: Number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_AWS_URL}/properties/getPropertiesByOwner/${id}`
      );
      setPropertyList(response?.data?.Data);
      setIsPropertyModalOpen(true);
    } catch (error: any) {
      if (error.response?.data?.status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const fetchManagerList = async (id: Number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_AWS_URL}/subUser/getManagers/${id}`
      );
      setManagerList(response?.data?.Data);
      setIsViewManagerModalOpen(true);
    } catch (error: any) {
      if (error.response?.data?.status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const handleViewManagers = (row: any) => {
    setSelectedManager(row);
    fetchManagerList(row._id);
  };

  const handleViewProperties = (row: any) => {
    setSelectedManager(row);
    fetchPropertyList(row._id);
  };

  const handleAddManagers = (row: any) => {
    setSelectedManager(row);
    setIsAddManagerModalOpen(true);
  };
  const handleAddProperties = (row: any) => {
    navigate(`/admin/owner-list/properties/addProperty/${row._id}`);
  };

  const columns: TableColumn<OwnerType>[] = [
    {
      name: "Name",
      selector: (row: { name: string }) => row.name,
      sortable: true,
      id: "Name",
      sortFunction: caseInsensitiveSort,
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
    {
      name: "Email",
      selector: (row: { email: string }) => row.email,
      id: "Email",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "lowercase",
      },
    },
    {
      name: "DOB",
      selector: (row: { DOB: string }) => row.DOB,
      sortable: true,
      id: "DOB",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
      },
      format: (row: { DOB: string }) => {
        const formattedDate = moment(row.DOB, "DD-MM-YYYY").format(
          "DD/MM/YYYY"
        );
        return moment(formattedDate, "DD/MM/YYYY").isValid()
          ? formattedDate
          : "Invalid Date";
      },
      sortFunction: dateSort,
    },
    {
      name: "Manage Owner",
      id: "manageOwner",
      cell: (row: { _id: number }) => (
        <div className="flex">
          <button
            onClick={() => handleEdit(row._id)}
            className="mr-2 rounded bg-orange-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          >
            <MdEditSquare className="h-4 w-4" />
          </button>
          <button
            onClick={() => showDeleteConfirmationModal(row._id)}
            className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
          >
            <RiDeleteBin5Line className="h-4 w-4" />
          </button>
        </div>
      ),
    },
    {
      name: "Add Manager",
      id: "addManagerOwner",
      cell: (row: { _id: number }) => (
        <div className="flex">
          <button
            onClick={() => handleAddManagers(row)}
            className="inline-flex items-center justify-center rounded-full bg-blue-100 p-2 transition duration-300 hover:bg-blue-200 focus:outline-none focus:ring focus:ring-blue-300"
          >
            <AiOutlineUserAdd className="h-6 w-6 text-blue-500" />
          </button>
        </div>
      ),
    },
    {
      name: "Add Property",
      id: "addManagerOwner",
      cell: (row: { _id: number }) => (
        <div className="flex">
          <button
            onClick={() => handleAddProperties(row)}
            className="inline-flex items-center justify-center rounded-full bg-blue-100 p-2 transition duration-300 hover:bg-blue-200 focus:outline-none focus:ring focus:ring-blue-300"
          >
            <BsFillHouseAddFill className="h-6 w-6 text-blue-500" />
          </button>
        </div>
      ),
    },
    {
      name: "View Details",
      id: "moreDetails",
      cell: (row: { _id: number }) => (
        <div className="flex">
          <button
            onClick={() => handleViewManagers(row)}
            className="ml-2 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
          >
            <span>Managers</span>
          </button>
          <button
            onClick={() => handleViewProperties(row)}
            className="ml-2 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
          >
            <span>Properties</span>
          </button>
        </div>
      ),
    },
  ];

  const filteredItems = useMemo(() => {
    return data?.filter((item) => {
      const nameMatch =
        item?.name &&
        item?.name?.toLowerCase()?.includes(filterText.toLowerCase());
      const emailMatch =
        item?.email &&
        item?.email?.toLowerCase()?.includes(filterText.toLowerCase());

      return nameMatch || emailMatch;
    });
  }, [data, filterText]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleAddUserDetails = () => {
      setAddUserDetails(true);
    };

    return (
      <FilterComponent
        onFilter={(e: ChangeEvent<HTMLInputElement>) =>
          setFilterText(e.target.value)
        }
        handleChangeDetails={handleAddUserDetails}
        filterText={filterText}
        addText={"Add New Owner"}
        icon={<BsPersonFillAdd className="h-6 w-6" />}
      />
    );
  }, [filterText]);

  return (
    <>
      {editDetails && (
        <EditModal
          owner={selectedUser}
          onClose={() => setEditDetails(false)}
          setData={setData}
          fetchData={fetchData}
        />
      )}
      {addUserDetails && (
        <AddOwner
          onClose={() => setAddUserDetails(false)}
          setData={setData}
          fetchData={fetchData}
        />
      )}
      {showDeleteConfirmation && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure you want to delete the owner?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleDeleteConfirmation}
                className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
              >
                Yes
              </button>
              <button
                onClick={hideDeleteConfirmationModal}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="owner-management-header">
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={
            <ReactLoading
              type={"cylon"}
              color={"#14aadd"}
              height={80}
              width={80}
            />
          }
        />
      </div>
      <ViewManagersModal
        isOpen={isViewManagerModalOpen}
        onClose={() => setIsViewManagerModalOpen(false)}
        managerList={managerList}
        selectedManager={selectedManager}
      />
      <ViewPropertyModal
        isOpen={isViewPropertyModalOpen}
        onClose={() => setIsPropertyModalOpen(false)}
        propertyList={propertyList}
        selectedManager={selectedManager}
      />
      {isAddManagerModalOpen && (
        <AddManager
          onClose={() => setIsAddManagerModalOpen(false)}
          setData={setData}
          fetchData={fetchData}
          selectedManager={selectedManager}
        />
      )}
    </>
  );
};

export default OwnerManagementList;
