import axios from "axios";
import ReactLoading from "react-loading";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { FiSearch, FiSend } from "react-icons/fi";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AdminData } from "../profile";
import "./style.css";

const validationSchema = Yup.object().shape({
  messageTitle: Yup.string().required("Title is required").trim(),
  messageSubject: Yup.string().required("Subject is required").trim(),
});

const initialValues = {
  messageTitle: "",
  messageSubject: "",
};

interface UserData {
  id: number;
  _id?: any;
  name: string;
  email: string;
  phone: string;
  role: "user" | "manager" | "owner";
}

const renderRoleBadge = (row: UserData) => {
  const roleColors = {
    manager:
      "w-40 text-center bg-green-300 text-green-700 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-sm dark:bg-green-900 dark:text-green-300",
    user: "w-40 text-center bg-yellow-300 text-yellow-700 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-sm dark:bg-yellow-900 dark:text-yellow-300",
    owner:
      "w-40 text-center bg-blue-300 text-blue-700 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-sm dark:bg-blue-900 dark:text-blue-300",
  };

  const colorClass = roleColors[row.role] || "bg-gray-600";
  return (
    <span
      className={`rounded-full px-5 py-1 ${colorClass} text-sm text-white shadow-md`}
    >
      {row.role.charAt(0).toUpperCase() + row.role.slice(1)}
    </span>
  );
};

const paginationOptions = {
  rowsPerPageText: "Items Per Page:",
  rangeSeparatorText: "of",
  selectAllRowsItem: true,
  selectAllRowsItemText: "All",
};

const NotificationManagementList = () => {
  // State for search text and user data
  const [searchText, setSearchText] = useState<string>("");
  const [userData, setUserData] = useState<UserData[]>([]);
  const [selectedRows, setSelectedRows] = useState<UserData[]>([]);
  const [selectedRole, setSelectedRole] = useState<
    "user" | "manager" | "owner" | "all"
  >("all");
  const [loading, setLoading] = useState(false);

  const fetchAdminDetails = async () => {
    try {
      const resp = await axios.get<AdminData>(
        `${process.env.REACT_APP_API_AWS_URL}/admin/profile`
      );
      return resp;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUserDetails = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_AWS_URL}/user/getProfileFromRole`)
      .then((response) => {
        setUserData(response.data?.Data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  // Function to handle sending a message
  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    if (selectedRows.length === 0) {
      const errorMessage = "Please select at least one user to proceed.";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeButton: false,
        toastId: "noUserSelectedError",
        className: "apple-toast",
      });
    } else {
      let selectUsers = selectedRows.map((user) => user._id);

      let senderId = localStorage.getItem("id");
      if (!senderId) {
        const details = await fetchAdminDetails();
        senderId = details?.data?._id;
      } // Data to be sent
      const messageData = {
        title: values.messageTitle,
        subject: values.messageSubject,
        selectUsers: selectUsers,
        senderId,
      };
      // Send the message
      axios
        .post(
          `${process.env.REACT_APP_API_AWS_URL}/properties/sendNotificationFromAdmin`,
          messageData
        )
        .then((response) => {
          // Clear the message input and selected tags
          toast.success(response?.data?.Message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeButton: false,
            className: "toast-success",
          });
          resetForm(); // Reset the form fields
        })
        .catch((error) => {
          toast.error(error?.response?.data?.error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeButton: false,
            className: "toast-error",
          });
        })
        .finally(() => {
          const updatedUserData = userData.map((user) => ({
            ...user,
            selected: false,
          }));
          console.log(updatedUserData, "updatedUserData");
          setUserData(updatedUserData);
          setSelectedRows([]);
        });
    }
  };

  // Define columns for user table
  const columnsForUsers = [
    {
      name: "Name",
      selector: (row: UserData) => row.name,
      sortable: true,
      cell: (row: UserData) => row.name,
    },
    {
      name: "Email",
      selector: (row: UserData) => row.email,
      sortable: false,
      cell: (row: UserData) => {
        return <div className="truncate">{row.email}</div>;
      },
    },
    {
      name: "Role",
      selector: (row: UserData) => row.role,
      sortable: false,
      cell: (row: UserData) => renderRoleBadge(row),
    },
  ];

  const columnsForManagers = [
    {
      name: "Name",
      selector: (row: UserData) => row.name,
      sortable: true,
      cell: (row: UserData) => row.name,
    },
    {
      name: "Email",
      selector: (row: UserData) => row.email,
      sortable: true,
      cell: (row: UserData) => {
        return <div className="truncate">{row.email}</div>;
      },
    },
    {
      name: "Role",
      selector: (row: UserData) => row.role,
      sortable: false,
      cell: (row: UserData) => renderRoleBadge(row),
    },
  ];

  // Filter user data based on search text and role
  const filteredUsers = useMemo(() => {
    return userData.filter((user) => {
      const nameMatch = user?.name
        ?.toLowerCase()
        ?.includes(searchText.toLowerCase());
      const emailMatch = user?.email
        ?.toLowerCase()
        ?.includes(searchText.toLowerCase());
      const roleMatch = selectedRole === "all" || user.role === selectedRole;
      return (nameMatch || emailMatch) && roleMatch;
    });
  }, [userData, searchText, selectedRole]);

  const selectableRowSelected = useCallback((row: any) => {
    return selectedRows.some((selectedItem) => selectedItem._id === row._id);
  }, []);

  return (
    <div className="animate-fadeIn flex flex-col space-y-8 md:flex-row md:space-x-8 md:space-y-0">
      <div className="md:w-3/5">
        <div className="notification-history-table-header space-y-4 rounded-lg bg-white p-6 shadow-md">
          <div className="flex flex-col md:flex-row md:space-x-4">
            <div className="relative flex-grow">
              <input
                type="text"
                id="searchHere"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search Here"
                className="w-full rounded-md border border-gray-300 px-4 py-2 text-gray-800 placeholder-gray-500 transition duration-300 hover:shadow-md focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-200"
              />
              <span className="absolute right-3 top-1/2 -translate-y-1/2 transform text-blue-500">
                <FiSearch />
              </span>
            </div>
            <div className="mt-4 md:ml-4 md:mt-0 md:w-auto">
              <select
                value={selectedRole}
                onChange={(e) =>
                  setSelectedRole(
                    e.target.value as "user" | "manager" | "owner" | "all"
                  )
                }
                className="w-full rounded-md border border-gray-300 px-4 py-2 transition duration-300 hover:shadow-md focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-200"
              >
                <option value="all">All</option>
                <option value="user">User</option>
                <option value="manager">Manager</option>
                <option value="owner">Owner</option>
              </select>
            </div>
          </div>
          <div className="animate-slideInUp notification-table font-sans">
            {userData.length > 0 ? (
              <DataTable
                columns={
                  selectedRole === "manager"
                    ? columnsForManagers
                    : columnsForUsers
                }
                data={filteredUsers}
                pagination
                highlightOnHover
                paginationComponentOptions={paginationOptions}
                selectableRows
                selectableRowSelected={selectableRowSelected}
                onSelectedRowsChange={(state) => {
                  setSelectedRows(state.selectedRows);
                }}
                progressPending={loading}
                progressComponent={
                  <ReactLoading
                    type={"cylon"}
                    color={"#14aadd"}
                    height={80}
                    width={80}
                  />
                }
              />
            ) : (
              <ReactLoading
                type={"cylon"}
                color={"#14aadd"}
                height={80}
                width={80}
                className="mx-auto"
              />
            )}
          </div>
        </div>
      </div>
      <div className="animate-slideInUp md:w-2/5">
        <div className="space-y-4 rounded-lg bg-white p-6 shadow-md">
          <h2 className="text-black text-2xl font-semibold">
            Send Notification
          </h2>
          {selectedRows.length > 0 ? (
            <div className="space-y-2">
              <label className="text-black block font-medium">
                Selected Users:
              </label>
              <div className="flex max-h-40 flex-wrap space-x-2 overflow-y-auto">
                {selectedRows.map((user) => (
                  <span
                    key={user._id}
                    className="my-1 inline-flex cursor-pointer items-center rounded-md bg-[#14aadd] px-3 py-1 font-medium text-white transition duration-300 hover:scale-105 hover:bg-[#007aff]"
                  >
                    {user.name}
                  </span>
                ))}
              </div>
            </div>
          ) : (
            <p className="text-gray-500">No users selected.</p>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="my-4 space-y-1">
                <label className="text-black block font-medium">
                  Title<span className="text-red-500">*</span>
                </label>
                <Field
                  type="text"
                  name="messageTitle"
                  className="w-full rounded-md border border-gray-300 px-4 py-2 text-gray-800 placeholder-gray-500 transition duration-300 focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-200"
                  placeholder="Enter the title"
                />
                <ErrorMessage
                  name="messageTitle"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="my-4 space-y-1">
                <label className="text-black block font-medium">
                  Subject<span className="text-red-500">*</span>
                </label>
                <Field
                  as="textarea"
                  name="messageSubject"
                  className="w-full rounded-md border border-gray-300 px-4 py-2 text-gray-800 placeholder-gray-500 transition duration-300 focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-200"
                  placeholder="Enter the subject"
                />
                <ErrorMessage
                  name="messageSubject"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="flex items-center space-x-2">
                <button
                  type="submit"
                  className="flex-grow rounded-md bg-blue-500 px-4 py-2 text-white transition duration-300 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                  Send <FiSend className="ml-2 inline-block" />
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default NotificationManagementList;
