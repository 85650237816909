import React from "react";
import { PropertyListTypes, managerList } from "..";

interface ViewMoreModalProps {
  isOpen: boolean;
  onClose: () => void;
  propertyList: Array<PropertyListTypes>;
  selectedManager: managerList;
}

const ViewPropertyModal: React.FC<ViewMoreModalProps> = ({
  isOpen,
  onClose,
  propertyList,
  selectedManager,
}) => {
  if (!isOpen || !propertyList) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="modal-container w-full max-w-md rounded-lg bg-white shadow-lg">
        <div className="flex items-end justify-end p-4 lg:pr-6">
          <button
            onClick={onClose}
            className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="modal-content max-h-[600px] overflow-y-auto p-5">
          <h2 className="mb-4 text-center text-2xl font-semibold">
            {selectedManager.name}'s Properties
          </h2>
          {propertyList.map((item, index) => (
            <div
              key={index}
              className="mb-6 flex items-center rounded border border-gray-300 p-4"
            >
              <div>
                <h3 className="text-lg font-medium">{item.name}</h3>
                <p className="text-gray-600">{item.type}</p>
              </div>
              <div className="ml-auto">
                {item.type === "gym" ? (
                  <span className="rounded-full bg-blue-500 px-2 py-1 text-xs font-semibold text-white">
                    Gym
                  </span>
                ) : (
                  <span className="rounded-full bg-green-500 px-2 py-1 text-xs font-semibold text-white">
                    Basketball
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewPropertyModal;
