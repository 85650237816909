import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import dummyImage from "assets/img/avatars/UserAvtar.png";
import { toast } from "react-toastify";
import Card from "components/card";
import ProfileField from "./components/ProfileField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

export interface AdminData {
  _id: string;
  name: string;
  email: string;
  DOB: string;
  role: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .trim()
    .matches(/^[a-zA-Z\s]*$/, "Only alphabets are allowed for the name")
    .test(
      "no-multiple-spaces",
      "Name should not have multiple spaces",
      (value) => !/\s{2,}/.test(value)
    ),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
    .test("valid-domain", "Invalid domain name in email", (value) =>
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
    ),
  DOB: Yup.string().required("Date of Birth is required"),
  role: Yup.string().required("Designation is required"),
});

const AdminProfile: React.FC = () => {
  const [responseData, setResponseData] = useState<AdminData | undefined>(
    undefined
  );
  const [editMode, setEditMode] = useState(false);

  const fetchAdminDetails = async () => {
    try {
      const response = await axios.get<AdminData>(
        `${process.env.REACT_APP_API_AWS_URL}/admin/profile`
      );
      setResponseData(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values: AdminData) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_AWS_URL}/admin/profile/${values?._id}`,
        {
          name: values?.name,
          email: values?.email,
          DOB: values?.DOB,
          role: values?.role,
        }
      );
      setEditMode(false);
      toast(response?.data?.Message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      fetchAdminDetails();
    } catch (error: any) {
      if (error?.response?.data?.Status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  useEffect(() => {
    fetchAdminDetails();
  }, []);

  const formatDateForDisplay = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "dd-MM-yyyy");
  };

  return (
    <Card extra="w-full mt-4 p-4 mx-auto max-w-[550px]">
      <div className="relative mt-1 flex h-24 w-full justify-center rounded-xl bg-cover">
        <div className="absolute flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <img className="h-full w-full rounded-full" src={dummyImage} alt="" />
        </div>
      </div>

      <div className="flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {responseData?.name}
        </h4>
        <p className="text-base font-normal text-gray-600">
          {responseData?.role}
        </p>
      </div>

      {editMode ? (
        <Formik
          initialValues={responseData}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {/* Form fields */}
              <div className="my-2 grid grid-cols-1 gap-4 sm:grid-cols-1">
                <ProfileField label="Name">
                  <Field
                    type="text"
                    name="name"
                    className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border border-gray-500 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
                  />
                  <ErrorMessage
                    name="name"
                    component="p"
                    className="text-sm text-red-500"
                  />
                </ProfileField>

                <ProfileField label="Email" value={responseData?.email || ""} />

                <ProfileField label="Date of Birth">
                  <div className="w-full rounded-xl border border-gray-500">
                    <DatePicker
                      name="DOB"
                      selected={values.DOB ? new Date(values.DOB) : null}
                      onChange={(date) => {
                        setFieldValue("DOB", date);
                      }}
                      dateFormat="dd-MM-yyyy"
                      maxDate={new Date()}
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={10}
                      className="mt-2 flex items-center justify-center bg-white/0 p-2 text-sm outline-none dark:!border-white/10 dark:text-white"
                    />
                  </div>
                  <ErrorMessage
                    name="DOB"
                    component="p"
                    className="text-sm text-red-500"
                  />
                </ProfileField>

                <ProfileField
                  label="Designation"
                  value={responseData?.role || ""}
                />
              </div>

              {/* Submit and Cancel buttons */}
              <div className="mt-4 flex justify-end">
                <button
                  type="submit"
                  className="rounded bg-[#14AADD] px-4 py-2 font-medium text-white hover:bg-blue-600"
                >
                  Save
                </button>
                <button
                  type="button"
                  className="ml-2 rounded bg-gray-500 px-4 py-2 font-medium text-white hover:bg-gray-600"
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <div className="my-2 grid grid-cols-1 gap-4 sm:grid-cols-1">
            <ProfileField label="Name" value={responseData?.name || ""} />
            <ProfileField label="Email" value={responseData?.email || ""} />
            <ProfileField
              label="Date of Birth"
              value={
                responseData?.DOB ? formatDateForDisplay(responseData.DOB) : ""
              }
            />
            <ProfileField
              label="Designation"
              value={responseData?.role || ""}
            />
          </div>

          {/* Edit button */}
          <div className="mt-4 flex justify-end">
            <button
              className="rounded bg-[#14AADD] px-4 py-2 font-medium text-white"
              onClick={() => setEditMode(true)}
            >
              Edit
            </button>
          </div>
        </>
      )}
    </Card>
  );
};

export default AdminProfile;
