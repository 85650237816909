import axios from "axios";
import ReactLoading from "react-loading";
import FilterComponent from "components/search";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { TableColumn } from "react-data-table-component";
import DataTable from "react-data-table-component";
import { MdEditSquare } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { toast } from "react-toastify";
import "./Table.css";
import EditManagerModal from "./components/EditModal";

interface ManagersType {
  _id: any;
  name: string;
  email: string;
  ownerId: string;
  ownerName: string;
}

const paginationOptions = {
  rowsPerPageText: "Items Per Page:",
  rangeSeparatorText: "of",
  selectAllRowsItem: true,
  selectAllRowsItemText: "All",
};

const caseInsensitiveSort = (rowA: ManagersType, rowB: ManagersType) => {
  const nameA = rowA.name.toLowerCase();
  const nameB = rowB.name.toLowerCase();
  const ownerNameA = rowA.ownerName.toLowerCase();
  const ownerNameB = rowB.ownerName.toLowerCase();

  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  } else {
    return ownerNameA.localeCompare(ownerNameB, undefined, {
      sensitivity: "base",
    });
  }
};

const ManagerManagementList: FC<any> = () => {
  const [filterText, setFilterText] = useState("");
  const [selectedUser, setSelectedUser] = useState<ManagersType | null>(null);
  const [editDetails, setEditDetails] = useState(false);
  const [data, setData] = useState<ManagersType[]>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const showDeleteConfirmationModal = (id: number) => {
    setDeleteUserId(id);
    setShowDeleteConfirmation(true);
  };

  const hideDeleteConfirmationModal = () => {
    setShowDeleteConfirmation(false);
    setDeleteUserId(null);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_AWS_URL}/subUser/deleteSubUser/${deleteUserId}`
      );
      toast(response?.data?.Message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      fetchData();
      hideDeleteConfirmationModal();
    } catch (error: any) {
      if (error.response?.data?.status === 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_AWS_URL}/subUser/getAllManagers`
      );
      setData(response?.data?.Data);
    } catch (error: any) {
      toast.error(error?.response?.data?.Message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (id: number) => {
    const user = data.find((user) => user._id === id);
    setSelectedUser(user);
    setEditDetails(true);
  };

  const columns: TableColumn<ManagersType>[] = [
    {
      name: "Name",
      selector: (row: { name: string }) => row.name,
      sortable: true,
      id: "Name",
      sortFunction: caseInsensitiveSort,
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
    {
      name: "Email",
      selector: (row: { email: string }) => row.email,
      id: "Email",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "lowercase",
      },
    },
    {
      name: "Owner Name",
      selector: (row: { ownerName: string }) => row.ownerName,
      id: "ownerName",
      style: {
        color: "black",
        fontWeight: "semibold",
        fontSize: "14px",
        textTransform: "capitalize",
      },
    },
    {
      name: "Actions",
      id: "Actions",
      cell: (row: { _id: number }) => (
        <div className="flex">
          <button
            onClick={() => handleEdit(row._id)}
            className="mr-2 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          >
            <MdEditSquare className="h-4 w-4" />
          </button>
          <button
            onClick={() => showDeleteConfirmationModal(row._id)}
            className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
          >
            <RiDeleteBin5Line className="h-4 w-4" />
          </button>
        </div>
      ),
    },
  ];

  const filteredItems = useMemo(() => {
    return data
      ?.filter((item) => {
        const nameMatch =
          item?.name &&
          item?.name?.toLowerCase()?.includes(filterText.toLowerCase());
        const emailMatch =
          item?.email &&
          item?.email?.toLowerCase()?.includes(filterText.toLowerCase());
        const ownerNameMatch =
          item?.ownerName &&
          item?.ownerName?.toLowerCase()?.includes(filterText.toLowerCase());
        return nameMatch || emailMatch || ownerNameMatch;
      })
      .map((item) => ({
        ...item,
        ownerName: item.ownerName || "-",
      }));
  }, [data, filterText]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e: ChangeEvent<HTMLInputElement>) =>
          setFilterText(e.target.value)
        }
        filterText={filterText}
      />
    );
  }, [filterText]);

  return (
    <>
      {editDetails && (
        <EditManagerModal
          manager={selectedUser}
          onClose={() => setEditDetails(false)}
          setData={setData}
          fetchData={fetchData}
        />
      )}
      {showDeleteConfirmation && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-50">
          <div className="rounded bg-white p-6">
            <p>Are you sure you want to delete the Manager ?</p>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleDeleteConfirmation}
                className="mr-2 rounded bg-red-500 px-4 py-2 text-white"
              >
                Yes
              </button>
              <button
                onClick={hideDeleteConfirmationModal}
                className="rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="manager-management-header">
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={
            <ReactLoading
              type={"cylon"}
              color={"#14aadd"}
              height={80}
              width={80}
            />
          }
        />
      </div>
    </>
  );
};

export default ManagerManagementList;
